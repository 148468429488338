import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NextStepStatus } from 'shared/features/WebSocket/constants'
import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { send } from 'shared/features/WebSocket/WebSocketService'
import consoleError from 'shared/utils/consoleError'

import { BuyStatus, StepName } from '../constants'
import { actions } from '../ducks'
import { getBuyStatus } from '../ducks/selectors'

type NextStepHook = {
  sendNextStep: () => void
}

export default (): NextStepHook => {
  const dispatch = useDispatch()
  const [isSellingInProgress, setIsSellingInProgress] = useState<boolean>(false)

  const buyStatus = useSelector(getBuyStatus)

  useEffect(() => {
    if (buyStatus === BuyStatus.selling) {
      setIsSellingInProgress(true)
      return
    }

    if (!isSellingInProgress) {
      return
    }

    if (buyStatus === BuyStatus.complete) {
      dispatch(actions.setIsStartSelling(false))
      setIsSellingInProgress(false)
      dispatch(actions.setActiveStep(StepName.Final))
    }

    if (buyStatus === BuyStatus.buying) {
      dispatch(actions.setIsStartSelling(false))
      setIsSellingInProgress(false)
      dispatch(actions.setActiveStep(StepName.Step1))
    }
  }, [buyStatus, isSellingInProgress])

  const sendNextStep = async () => {
    try {
      const status = await send<NextStepStatus>(WebSocketMessageService.getNextStepMessage())
      if (status === NextStepStatus.selling) {
        dispatch(actions.setIsStartSelling(true))
      }
    } catch (error) {
      dispatch(actions.setIsStartSelling(false))
      consoleError(error)
    }
  }

  return { sendNextStep }
}
