import React, { memo, useState } from 'react'
import cn from 'clsx'

import { NString } from 'shared/types'

import styles from './Image.module.css'

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  className?: string
  srcFallback?: NString
  canDownload?: boolean
}

const Image: React.FC<ImageProps> = ({
  src,
  srcSet,
  alt,
  className,
  srcFallback,
  canDownload = true,
  ...props
}): React.ReactElement => {
  const [error, setError] = useState<boolean>(false)

  const image = error && srcFallback ? srcFallback : src

  const onError = () => {
    setError(true)
  }

  const openContextMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    return !canDownload ? event.preventDefault() : null
  }

  return (
    <img
      onContextMenu={openContextMenu}
      className={cn(className, styles.image)}
      src={image}
      srcSet={srcSet}
      alt={alt}
      onError={onError}
      {...props}
    />
  )
}

export default memo(Image)
