import React from 'react'

import Image from 'shared/components/Image'
import Typography from 'shared/components/Typography'
import { Player } from 'shared/features/Buy/types'

import styles from './Step3.module.css'

type Props = {
  players: Player[]
  onClick: (player: Player) => void
}

export const PlayersSearchList: React.FC<Props> = ({ players, onClick }) => {
  const list = players.map((player, index) => {
    const {
      id,
      image,
      display_name_with_rating: nameWithRating,
      shell: { tag },
      rating,
    } = player

    return (
      <div
        key={id}
        className={styles.searchList__item}
        role="button"
        tabIndex={index}
        onClick={() => {
          onClick(player)
        }}
      >
        <div className={styles.searchList__left}>
          <div className={styles.searchList__image}>
            <Image src={image} />
          </div>
          <Typography uppercase size="xs">
            {nameWithRating}
          </Typography>
        </div>
        <div className={styles.searchList__right}>
          <Typography weight="medium" size="xs">
            {tag}
          </Typography>
          <div className={styles.searchList__rating}>
            <Typography color="white" size="xs" weight="medium">
              {rating}
            </Typography>
          </div>
        </div>
      </div>
    )
  })

  return <div className={styles.searchList}>{!!players.length && list}</div>
}
