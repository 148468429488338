import React, { useMemo } from 'react'

import Typography from 'shared/components/Typography'
import formatNumber from 'shared/utils/formatNumber'

import styles from './ProgressBar.module.css'

type Props = {
  total: number
  value?: number
}

export const ProgressBar: React.FC<Props> = ({ total, value = 0 }) => {
  const percentage = useMemo(() => Math.ceil((value / total) * 100), [total, value])

  return (
    <div className={styles.progress}>
      <div className={styles.progress__bg}>
        <div className={styles.progress__fill} style={{ width: `${percentage}%` }} />
      </div>
      <div>
        <Typography component="span" weight="light" size="s" uppercase>
          {`Доставлено: `}
        </Typography>
        <Typography component="span" weight="bold" size="s">
          {value}
        </Typography>
        <Typography component="span" weight="light" size="s">
          {` из `}
        </Typography>
        <Typography component="span" weight="bold" size="s">
          {formatNumber(total)}
        </Typography>
      </div>
    </div>
  )
}
