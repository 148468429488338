import React from 'react'
import { useSelector } from 'react-redux'

import Button from 'shared/components/Button'
import { PlatformIcon } from 'shared/components/PlatformIcon/PlatformIcon'
import Typography from 'shared/components/Typography'
import formatNumber from 'shared/utils/formatNumber'

import { getAmountBought, getAmountNeeded, getBuyInfoPlatform, getIsStartSelling } from '../../ducks/selectors'
import useStopSession from '../../hooks/useStopSession'
import { getDisplayNameByPlatform } from '../../utils/getDisplayNameByPlatform'
import { ProgressBar } from '../ProgressBar'

import styles from './HeadInfo.module.css'

export const HeadInfo = () => {
  const { stopSession, isLoading } = useStopSession()
  const platform = useSelector(getBuyInfoPlatform)
  const amountNeeded = useSelector(getAmountNeeded)
  const amountBought = useSelector(getAmountBought)
  const isStartSelling = useSelector(getIsStartSelling)

  const platformName = getDisplayNameByPlatform(platform)

  return (
    <section className={styles.container}>
      <div className={styles.info}>
        <div className={styles.preview}>
          <img src="/images/futcoin.png" alt="futcoin" className={styles.preview__icon} />
          <div className={styles.preview__label_value}>
            <Typography weight="light" size="s">
              Количество
            </Typography>
            <Typography weight="bold" size="xxxl">
              {formatNumber(amountNeeded)}
            </Typography>
          </div>
        </div>
        <div className={styles.preview}>
          <PlatformIcon platform={platform} className={styles.preview__icon} />
          <div className={styles.preview__label_value}>
            <Typography weight="light" size="s">
              Платформа
            </Typography>
            <Typography weight="bold" size="xxxl">
              {platformName}
            </Typography>
          </div>
        </div>
      </div>
      <ProgressBar total={amountNeeded} value={amountBought} />
      {!isStartSelling && !amountBought && (
        <Button
          className={styles.stop}
          onClick={stopSession}
          color="error"
          outline
          size="s"
          uppercase={false}
          loading={isLoading}
          disabled={isLoading}
        >
          Отменить заказ
        </Button>
      )}
    </section>
  )
}
