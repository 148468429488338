import React from 'react'

import styles from './InstructionTabs.module.css'

type Props = {
  src: string
}

export const Images: React.FC<Props> = ({ src }) => (
  <div className={styles.images}>
    <img src="/images/bg_pattern_gray.png" alt="bg_pattern_gray" className={styles.images_bg} />
    <img src={src} alt="slide" className={styles.images_slide} />
  </div>
)
