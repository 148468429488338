import React, { useEffect, useMemo, useState } from 'react'
import cn from 'clsx'

import { useWindowSize } from 'shared/hooks/useWindowSize'

import styles from './Divider.module.css'

const createLines = (size: number) => Array.from(new Array(size).keys())

type Props = {
  className?: string
}

export const Divider: React.FC<Props> = ({ className }) => {
  const { isMobile } = useWindowSize()

  const [size, setSize] = useState<number>(42)

  const lines = useMemo(() => createLines(size), [size])

  useEffect(() => {
    if (isMobile) {
      setSize(36)
    }
  }, [isMobile])

  return (
    <div className={cn(styles.divider, className)}>
      {lines.map(line => (
        <div key={line} className={styles.divider__item} />
      ))}
    </div>
  )
}
