import { Player, PlayerCardProps } from '../types'

export default (player: Player, startPrice: number, nowPrice?: number, expirationDate?: string): PlayerCardProps => {
  const {
    shell: { image: backgroundSrc, color1: color },
    club: { image: clubImageSrc },
    country: { image: countryImageSrc },
    image: portraitImageSrc,
    display_name: name,
    rating,
    id,
  } = player

  return {
    id,
    backgroundSrc,
    clubImageSrc,
    countryImageSrc,
    name,
    portraitImageSrc,
    startPrice,
    nowPrice,
    rating,
    expirationDate,
    color,
  }
}
