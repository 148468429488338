import React, { useMemo } from 'react'
import Rating from 'react-rating'
import cn from 'clsx'

import getDate from 'shared/utils/getDate'
import { ReactComponent as RatingIcon } from 'shared/assets/icons/rating.svg'
import { ReactComponent as RatingOutlineIcon } from 'shared/assets/icons/rating_outline.svg'
import { ReactComponent as UserIcon } from 'shared/assets/icons/user.svg'
import Typography from 'shared/components/Typography'

import { Review } from '../../types'

import styles from './ReviewItem.module.css'

type Props = Review & {
  className?: string
}

export const ReviewItem: React.FC<Props> = ({ text, login, rating, date, className }) => {
  const reviewDate = useMemo(() => getDate(date), [date])

  return (
    <div className={cn(styles.root, className)}>
      <Rating
        initialRating={rating}
        readonly
        fullSymbol={<RatingIcon className={cn(styles.rating__star, styles.rating__star_fill)} />}
        emptySymbol={<RatingOutlineIcon className={cn(styles.rating__star, styles.rating__star_outline)} />}
      />
      <Typography weight="light" size="l" spacy className={styles.text}>
        {text}
      </Typography>
      <div className={styles.info}>
        <div className={styles.login}>
          <UserIcon className={styles.login__icon} />
          <Typography weight="bold" size="m">
            {login}
          </Typography>
        </div>
        <Typography weight="light">{reviewDate}</Typography>
      </div>
    </div>
  )
}
