import React from 'react'
import { Helmet } from 'react-helmet-async'

import { home as homeRoute } from 'shared/constants/routes'

export const Headers = () => (
  <Helmet>
    <title>{homeRoute.title}</title>
    <meta property="yandex_recommendations_title" content={homeRoute.title} />
    <meta
      property="yandex_recommendations_image"
      content="https://www.ea.com/fifa/ultimate-team/web-app/content/21D4F1AC-91A3-458D-A64E-895AA6D871D1/2021/fut/sbc/companion/sets/images/sbc_set_image_10002350-6c7925ef-a043.png"
    />
  </Helmet>
)
