import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { send } from 'shared/features/WebSocket/WebSocketService'
import consoleError from 'shared/utils/consoleError'

import { getBoughtTrades } from '../ducks/selectors'
import { Lot, RequiredPlayersCount, Trade } from '../types'

type SearchAuctionsHook = {
  auctions: Trade[]
  selectTrades: Trade[]
  sendSearchAuctions: (id: number) => void
  sendSelectTrade: (id: string) => void
  resetAuctions: () => void
  isStartSearchAuctions: boolean
  isPlayersNotFound: boolean
  isAllPlayersFound: boolean
  isNeedToSelectTrade: boolean
}

export default (requiredPlayersCount: RequiredPlayersCount): SearchAuctionsHook => {
  const [auctions, setAuctions] = useState<Trade[]>(useSelector(getBoughtTrades))
  const [selectTrades, setSelectTrades] = useState<Trade[]>([])
  const [isStartSearchAuctions, setIsStartSearchAuctions] = useState<boolean>(false)
  const [isPlayersNotFound, setIsPlayersNotFound] = useState<boolean>(false)
  const [isAllPlayersFound, setIsAllPlayersFound] = useState<boolean>(false)
  const [isNeedToSelectTrade, setIsNeedToSelectTrade] = useState<boolean>(false)

  const resetAuctions = () => {
    setAuctions([])
    setSelectTrades([])
    setIsPlayersNotFound(false)
    setIsNeedToSelectTrade(false)
  }

  useEffect(() => {
    setIsAllPlayersFound(auctions.length === requiredPlayersCount)
  }, [auctions])

  const sendSearchAuctions = async (id: number) => {
    try {
      setIsStartSearchAuctions(true)
      setIsPlayersNotFound(false)
      setIsNeedToSelectTrade(false)
      setSelectTrades([])
      const trades = await send<Trade[]>(WebSocketMessageService.getSearchAuctionsMessage(id))
      setIsStartSearchAuctions(false)
      if (!trades.length) {
        setIsPlayersNotFound(true)
        return
      }
      if (trades.length > 1) {
        setIsNeedToSelectTrade(true)
        setSelectTrades(trades)
        return
      }
      setAuctions(prev => [...prev, ...trades])
    } catch (error) {
      setIsStartSearchAuctions(false)
      consoleError(error)
    }
  }

  const sendSelectTrade = async (id: string) => {
    try {
      setIsStartSearchAuctions(true)
      setIsPlayersNotFound(false)
      const lot = await send<Lot>(WebSocketMessageService.getSelectTradeMessage(id))
      const { trade } = lot
      setAuctions(prev => [...prev, trade])
      setSelectTrades([])
      setIsStartSearchAuctions(false)
    } catch (error) {
      setIsStartSearchAuctions(false)
      consoleError(error)
    }
  }

  return {
    sendSearchAuctions,
    sendSelectTrade,
    resetAuctions,
    auctions,
    selectTrades,
    isStartSearchAuctions,
    isPlayersNotFound,
    isAllPlayersFound,
    isNeedToSelectTrade,
  }
}
