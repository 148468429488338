import React from 'react'
import cn from 'clsx'

import Image from 'shared/components/Image'
import Typography from 'shared/components/Typography'
import { ReactComponent as CheckIcon } from 'shared/assets/icons/success-rounded.svg'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close-fill.svg'

import { ShellCardProps } from '../../types'

import styles from './ShellCards.module.css'

type Props = ShellCardProps & {
  isActive: boolean
}

export const ShellCard: React.FC<Props> = ({ backgroundSrc, clubImageSrc, countryImageSrc, rating, isActive }) => {
  const Icon = isActive ? CheckIcon : CloseIcon

  return (
    <div className={styles.cardWrap}>
      <div className={cn(styles.card, { [styles.card__active]: isActive })}>
        <Image src={backgroundSrc} alt="Player background" className={styles.card__bg} />
        <Image src={clubImageSrc} alt="Player club" className={styles.card__club} />
        <Image src={countryImageSrc} alt="Player country" className={styles.card__country} />
        <Typography color="white" size="xl" className={styles.card__rating}>
          {rating}
        </Typography>
      </div>
      <Icon className={cn(styles.card__icon, { [styles.card__active__icon]: isActive })} />
    </div>
  )
}
