import { mainStepsTabActiveIcon, mainStepsTabIcon, StepName } from '../constants'

export const getIconByTabName = (
  name: StepName,
  isActive: boolean,
): React.FC<React.SVGProps<SVGSVGElement>> | undefined => {
  if (name === StepName.Payment || name === StepName.Final) {
    return undefined
  }

  return isActive ? mainStepsTabActiveIcon[name] : mainStepsTabIcon[name]
}
