import { useEffect, useRef } from 'react'

export default (callback: () => void, delay = 1000): NodeJS.Timer | undefined => {
  const intervalId = useRef<NodeJS.Timer>()
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()
    intervalId.current = setInterval(tick, delay)
    return () => clearTimeout(intervalId.current)
  }, [])

  return intervalId.current
}
