import React, { memo } from 'react'

import ErrorBoundary from 'shared/components/ErrorBoundary'
import { BaseLayout } from 'shared/core/layouts/BaseLayout'

import { Content } from './components/Content'
import { Headers } from './components/Headers'

const Home: React.FC = (): React.ReactElement => {
  return (
    <ErrorBoundary>
      <Headers />

      <BaseLayout>
        <Content />
      </BaseLayout>
    </ErrorBoundary>
  )
}

export default memo(Home)
