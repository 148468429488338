import React from 'react'
import Rating from 'react-rating'
import cn from 'clsx'

import { ReactComponent as RatingIcon } from 'shared/assets/icons/rating.svg'
import { ReactComponent as RatingOutlineIcon } from 'shared/assets/icons/rating_outline.svg'
import { ReactComponent as UserIcon } from 'shared/assets/icons/user.svg'
import Typography from 'shared/components/Typography'

import { Review } from '../../typings'

import styles from './ReviewItem.module.css'

type Props = {
  className?: string
  style?: React.CSSProperties
} & Review

export const ReviewItem: React.FC<Props> = ({ person, rating, text, className, style }) => (
  <div className={cn(styles.review, className)} style={style}>
    <div className={styles.review__main}>
      <Rating
        initialRating={rating}
        readonly
        fullSymbol={<RatingIcon className={styles.star} />}
        emptySymbol={<RatingOutlineIcon className={styles.star} />}
      />
      <div className={styles.review__text}>
        <Typography weight="light" size="l" spacy>
          {text}
        </Typography>
        <div className={styles.review__text_gradient} />
      </div>
    </div>
    <div className={styles.person}>
      <UserIcon className={styles.person__icon} />
      <Typography size="m" weight="bold">
        {person}
      </Typography>
    </div>
  </div>
)
