import * as yup from 'yup'

import { FormPayload } from './RatingForm'

export default yup.object<FormPayload>().shape<FormPayload>({
  rating: yup.number().min(1, 'Пожалуйста, оцените заказ').required('Пожалуйста, оцените заказ'),
  text: yup
    .string()
    .max(1000, 'Введите не более 1000 символов')
    .matches(/^[a-zа-яё\s~!@#$%^&*()_+-={}|\\:";'<>?,./]+$/i, 'Введены недопустимые символы'),
})
