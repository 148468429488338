import React from 'react'

import { StepTitle } from 'shared/features/Buy/constants'

import { Step } from '../Step'

import { SendForm } from './SendForm'

export const Step3 = () => (
  <Step title={StepTitle.Step3}>
    <SendForm />
  </Step>
)
