import React from 'react'

import { Review } from 'shared/features/Reviews/types'

import { ReviewItem } from '../ReviewItem'

import styles from './ReviewsMobileSlider.module.css'

type Props = {
  reviews: Review[]
}

export const ReviewsMobileSlider: React.FC<Props> = ({ reviews }) => {
  const list = reviews.map(({ rating, text, login, id }) => (
    <ReviewItem key={id} person={login} rating={rating} text={text} />
  ))

  return <div className={styles.reviews}>{list}</div>
}
