import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as buyActions } from 'shared/features/Buy/ducks'

import { getPaymentIsLoading, getPaymentUrl } from '../ducks/selectors'
import { PaymentRequest } from '../types'

type CreatePaymentHook = {
  paymentIsLoading: boolean
  handlePayment(payload: PaymentRequest): void
}

export default (): CreatePaymentHook => {
  const dispatch = useDispatch()

  const paymentUrl = useSelector(getPaymentUrl)
  const paymentIsLoading = useSelector(getPaymentIsLoading)

  const handlePayment = (request: PaymentRequest) => {
    dispatch(buyActions.handlePayment(request))
  }

  useEffect(() => {
    if (paymentUrl) {
      window.location.replace(paymentUrl)
    }
  }, [paymentUrl])

  return {
    handlePayment,
    paymentIsLoading,
  }
}
