import React, { useMemo } from 'react'
import cn from 'clsx'

import styles from './InputSlider.module.css'

type Props = {
  name?: string
  step?: number
  max?: number
  min?: number
  value?: number
  className?: string
  reset?: () => void
} & React.InputHTMLAttributes<HTMLInputElement>

export const InputSlider: React.FC<Props> = ({
  name,
  className,
  step = 10,
  max = 100,
  min = 0,
  value = 0,
  ...rest
}) => {
  const percentage: number = useMemo(() => {
    if (value < min) {
      return 0
    }

    if (value > max) {
      return 100
    }

    return ((value - min) / (max - min)) * 100
  }, [value, max])

  return (
    <div className={cn(styles.container, className)}>
      <input
        name={name}
        type="range"
        value={value}
        className={styles.input}
        max={max}
        min={min}
        step={step}
        {...rest}
      />
      <div className={styles.track} />
      <div className={styles.thumb} style={{ left: `calc(${percentage}% - ${(23 * (percentage || 0)) / 100}px)` }} />
      <div className={styles.fill} style={{ width: `calc(${percentage}% - ${(23 * (percentage || 0)) / 100}px)` }} />
    </div>
  )
}
