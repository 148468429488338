import React from 'react'

import { useCountdown } from 'shared/hooks/useCountdown'

import { formatTime, secondsToTimeout } from '../Timer/utils'
import Typography, { TypographyProps } from '../Typography'

import { getDiffTimeInSeconds } from './utils'

interface Props extends TypographyProps {
  expirationDate: string
}

export const PlayerCardTimer: React.FC<Props> = ({
  expirationDate,
  size = 'xs',
  uppercase = true,
  weight = 'bold',
  ...rest
}) => {
  const time = useCountdown(getDiffTimeInSeconds(expirationDate))

  const { hours, minutes, seconds } = secondsToTimeout(time)

  return (
    <Typography {...rest} size={size} uppercase={uppercase} weight={weight}>
      {`Время: ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`}
    </Typography>
  )
}
