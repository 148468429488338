import * as yup from 'yup'

import { FormPayload } from './SendForm'

export default yup.object<FormPayload>().shape<FormPayload>({
  isShowEmail: yup.boolean().required(),
  isVisibleInput: yup.boolean().required(),
  email: yup.string().when('isShowEmail', {
    is: true,
    then: yup.string().email('E-mail указан некорректно').required('E-mail обязателен для заполнения'),
  }),
  ftp: yup.number().required('FTP обязателен для заполнения'),
  money: yup.number().required('Сумма обязательна для заполнения'),
})
