import React, { useMemo, useRef, useState } from 'react'
import cn from 'clsx'

import { Review } from 'shared/features/Reviews/types'
import { ReactComponent as ArrowLeft } from 'shared/assets/icons/arrow_left.svg'
import { ReactComponent as ArrowRight } from 'shared/assets/icons/arrow_right.svg'
import Button from 'shared/components/Button'

import { ReviewItem } from '../ReviewItem'

import styles from './ReviewsSlider.module.css'
import getSliderWidth from './utils/getSliderWidth'

type Props = {
  reviews: Review[]
  count?: number
  itemWidth?: number
  gap?: number
}

export const ReviewsSlider: React.FC<Props> = ({ reviews, count = 10, itemWidth = 370, gap = 12 }) => {
  const [sliderWidth] = useState<number>(getSliderWidth(count, itemWidth, gap))
  const [position, setPosition] = useState<number>(0)

  const sliderRef = useRef<HTMLDivElement>(null)
  const currentSliderWidth = useMemo(() => sliderRef.current?.getBoundingClientRect().width, [sliderRef.current])

  const dx = itemWidth + gap
  const isShowRightButton =
    currentSliderWidth && currentSliderWidth < sliderWidth && position + currentSliderWidth < sliderWidth

  const translateLeft = () => {
    const offset = position > dx ? dx : position
    setPosition(prev => prev - offset)
  }
  const translateRight = () => {
    if (currentSliderWidth) {
      const hiddenScoll = sliderWidth - currentSliderWidth - position
      const offset = hiddenScoll < dx ? hiddenScoll : dx
      setPosition(prev => prev + offset)
    }
  }

  return (
    <div className={styles.reviewsWrap}>
      {!!position && (
        <Button
          size="l"
          color="gray"
          circle
          icon={ArrowLeft}
          onClick={translateLeft}
          className={cn(styles.reviewsWrap__button, styles.reviewsWrap__button_left)}
        />
      )}
      <div
        className={styles.reviews}
        style={{ transform: `translateX(-${position}px)`, gap: `${gap}px` }}
        ref={sliderRef}
      >
        {reviews.map(({ rating, text, login, id }) => (
          <ReviewItem style={{ flex: `0 0 ${itemWidth}px` }} key={id} person={login} rating={rating} text={text} />
        ))}
      </div>
      {isShowRightButton && (
        <Button
          size="l"
          color="gray"
          circle
          icon={ArrowRight}
          onClick={translateRight}
          className={cn(styles.reviewsWrap__button, styles.reviewsWrap__button_right)}
        />
      )}
    </div>
  )
}
