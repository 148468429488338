import React from 'react'

import Container from 'shared/core/layouts/Container'
import { Divider } from 'shared/components/Divider'
import { Heading } from 'shared/components/Heading'

import { InstructionTabs } from '../InstructionTabs'
import { ScrollTo } from '../../constants'

import styles from './HowTo.module.css'

export const HowTo = () => (
  <Container className={styles.root} id={ScrollTo.howToBlock}>
    <Divider className={styles.divider} />
    <Heading color="white" className={styles.heading}>
      Как купить монеты?
    </Heading>
    <InstructionTabs />
  </Container>
)
