import { Formik, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import Rating from 'react-rating'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'clsx'

import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import { getUser } from 'shared/features/Profile/ducks/selectors'
import { actions as reviewsActions } from 'shared/features/Reviews/ducks'
import { ReactComponent as RatingOutlineIcon } from 'shared/assets/icons/rating_outline.svg'
import { ReactComponent as RatingIcon } from 'shared/assets/icons/rating.svg'
import { getCreateReviewIsLoading, getCreateReviewIsSuccess } from 'shared/features/Reviews/ducks/selectors'
import Tooltip from 'shared/components/Tooltip'
import Typography from 'shared/components/Typography'
import { actions as buyActions } from 'shared/features/Buy/ducks'

import { ReviewSuccessModal } from '../../Modals/ReviewSuccessModal'

import styles from './Final.module.css'
import validationSchema from './validationSchema'

export type FormPayload = {
  rating: number
  text?: string
}

export const RatingForm = () => {
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const createReviewIsLoading = useSelector(getCreateReviewIsLoading)
  const createReviewIsSuccess = useSelector(getCreateReviewIsSuccess)

  const initialValues: FormPayload = {
    rating: 0,
    text: '',
  }

  const onSubmitHandle = (payload: FormPayload) => {
    if (!user) {
      return
    }
    const { rating, text } = payload
    const { login } = user
    dispatch(
      reviewsActions.createReview({
        login,
        rating,
        text,
      }),
    )
  }

  const onConfirmSuccessModal = () => {
    dispatch(reviewsActions.reset())
    dispatch(buyActions.reset())
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandle} validationSchema={validationSchema}>
      {({
        handleSubmit,
        setFieldValue,
        handleChange,
        validateForm,
        values: { rating, text },
        errors,
      }: FormikProps<FormPayload>): React.ReactElement => {
        const { rating: ratingError, text: textError } = errors
        useEffect(() => {
          validateForm()
        }, [])

        const onChangeRating = (value: number) => {
          setFieldValue('rating', value)
        }

        const isButtonDisabled = !!ratingError

        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Rating
              className={styles.rating}
              onChange={onChangeRating}
              initialRating={rating}
              fullSymbol={<RatingIcon className={cn(styles.rating__star, styles.rating__star_fill)} />}
              emptySymbol={<RatingOutlineIcon className={cn(styles.rating__star, styles.rating__star_outline)} />}
            />
            <div className={styles.input}>
              <Input placeholder="Ваш отзыв" name="text" fullWidth value={text} onChange={handleChange} />
              {!!textError && (
                <Typography color="error" size="xs" className={styles.error}>
                  {textError}
                </Typography>
              )}
            </div>
            <Tooltip title={ratingError} disabled={!isButtonDisabled}>
              <Button
                className={styles.submit}
                type="submit"
                loading={createReviewIsLoading}
                disabled={isButtonDisabled}
              >
                Отправить
              </Button>
            </Tooltip>
            {createReviewIsSuccess && <ReviewSuccessModal confirm={onConfirmSuccessModal} />}
          </form>
        )
      }}
    </Formik>
  )
}
