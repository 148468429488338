import React from 'react'
import cn from 'clsx'

import Typography from 'shared/components/Typography'
import priceFormatter from 'shared/utils/priceFormatter'
import Image from 'shared/components/Image'
import FutCoin from 'shared/components/FutCoin'
import { PlayerCardProps } from 'shared/features/Buy/types'

import styles from './PlayerCard.module.css'
import { PlayerCardTimer } from './PlayerCardTimer'

export const PlayerCard: React.FC<PlayerCardProps> = ({
  className,
  backgroundSrc,
  clubImageSrc,
  countryImageSrc,
  portraitImageSrc,
  name,
  color,
  rating,
  startPrice,
  nowPrice,
  expirationDate,
}) => {
  const textStyle: React.CSSProperties = {
    color: `${color}`,
  }
  return (
    <div className={cn(className, styles.card)}>
      <Image src={backgroundSrc} alt="Player background" className={styles.bg} />
      <Image src={portraitImageSrc} alt="Player portrait" className={styles.portrait} />
      <div className={styles.badges}>
        <Typography weight="bold" color="white" size="l" className={styles.rating} style={textStyle}>
          {rating}
        </Typography>
        <Image src={countryImageSrc} alt="Player country" />
        <Image src={clubImageSrc} alt="Player club" />
      </div>
      <div className={styles.info}>
        <Typography
          size="xs"
          uppercase
          className={styles.name}
          color="white"
          ellipsis
          weight="medium"
          style={textStyle}
        >
          {name}
        </Typography>
        <div className={cn(styles.price, styles.price_start)}>
          <FutCoin className={styles.futCoin} />
          <Typography size="xs" uppercase color="white" weight="medium" style={textStyle}>
            {priceFormatter(startPrice)}
          </Typography>
        </div>
        {nowPrice && (
          <div className={cn(styles.price, styles.price_now)}>
            <FutCoin className={styles.futCoin} />
            <Typography size="xs" uppercase color="white" weight="medium" style={textStyle}>
              {priceFormatter(nowPrice)}
            </Typography>
          </div>
        )}
        {expirationDate && <PlayerCardTimer expirationDate={expirationDate} style={textStyle} />}
      </div>
    </div>
  )
}
