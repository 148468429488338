import React from 'react'

import Container from 'shared/core/layouts/Container'
import { ReactComponent as GiftIcon } from 'shared/assets/icons/gift.svg'
import Image from 'shared/components/Image'
import Typography from 'shared/components/Typography'

import styles from './BonusInfo.module.css'

export const BonusInfo = () => (
  <Container className={styles.root}>
    <div className={styles.content}>
      <GiftIcon className={styles.icon} />
      <div className={styles.info}>
        <Typography size="xxxl" weight="bold" uppercase className={styles.info__title}>
          Получайте
          <Typography size="xxxl" weight="bold" uppercase color="brand">
            &nbsp;бонусы
          </Typography>
        </Typography>
        <Typography size="l" weight="light" spacy className={styles.info__description}>
          С каждой транзакции вы получаете футопоинты, которыми можно оплатить дальнейшие заказы на FuttyCoin
        </Typography>
      </div>
    </div>
    <Image src="images/bonus.png" className={styles.image} />
  </Container>
)
