import React from 'react'

import { ReactComponent as LogoMir } from 'shared/assets/icons/pay-mir.svg'
import { ReactComponent as LogoMasterCard } from 'shared/assets/icons/pay-master-card.svg'
import { ReactComponent as LogoVisa } from 'shared/assets/icons/pay-visa.svg'

import styles from './Payment.module.css'

type Props = {
  className?: string
}

export const PaymentIcons: React.FC<Props> = ({ className }) => (
  <div className={className}>
    <LogoMir className={styles.description_icon} />
    <LogoMasterCard className={styles.description_icon} />
    <LogoVisa className={styles.description_icon} />
  </div>
)
