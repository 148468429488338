import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Button from 'shared/components/Button'
import FutCoin from 'shared/components/FutCoin'
import { getRecommendedBuyPrice } from 'shared/features/Buy/ducks/selectors'
import { useFindAnotherPlayer } from 'shared/features/Buy/hooks/useFindAnotherPlayer'
import { RequiredPlayer as TRequiredPlayer } from 'shared/features/Buy/types'
import mapDataToPlayerCardProps from 'shared/features/Buy/utils/mapDataToPlayerCardProps'
import mapDataToShellCardsProps from 'shared/features/Buy/utils/mapDataToShellCardsProps'
import formatNumber from 'shared/utils/formatNumber'
import { InfoCardProps, InfoCardSize } from 'shared/components/InfoCard'
import { PlayerCard } from 'shared/components/PlayerCard'

import { InfoCards } from '../../InfoCards/InfoCards'
import { FindAnotherPlayerModal } from '../../Modals/FindAnotherPlayerModal'
import { NextStepButton } from '../../NextStepButton'
import { ShellCards } from '../../ShellCards'

import styles from './Step1.module.css'
import { SearchLoading } from './SearchLoading'

type Props = {
  requiredPlayer: TRequiredPlayer
}

export const RequiredPlayer: React.FC<Props> = ({ requiredPlayer }) => {
  const { isStartSearch, findAnotherPlayer } = useFindAnotherPlayer()

  const recommendedBuyPrice = useSelector(getRecommendedBuyPrice)
  const playerCardProps = mapDataToPlayerCardProps(requiredPlayer, recommendedBuyPrice)
  const shellCardsProps = mapDataToShellCardsProps(requiredPlayer)

  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const retryHandler = () => {
    findAnotherPlayer()
    setIsShowModal(false)
  }

  const declineHandler = () => {
    setIsShowModal(false)
  }

  const cards: InfoCardProps[] = [
    {
      label: 'Игрок:',
      value: requiredPlayer.display_name_with_rating,
      icon: <PlayerCard {...playerCardProps} />,
      size: InfoCardSize.m,
    },
    {
      label: 'Не дороже:',
      value: `${formatNumber(recommendedBuyPrice)} монет`,
      icon: <FutCoin className={styles.icon} />,
      size: InfoCardSize.m,
    },
  ]

  return (
    <div className={styles.requiredPlayer}>
      <InfoCards cards={cards} className={styles.cards} />
      <ShellCards cards={shellCardsProps} className={styles.shields} playerId={requiredPlayer.id} />
      <div className={styles.actions}>
        <div className={styles.action}>
          <Button
            fullWidth
            outline
            onClick={() => {
              setIsShowModal(true)
            }}
          >
            Нет по такой цене?
          </Button>
        </div>
        <div className={styles.action}>
          <NextStepButton fullWidth />
        </div>
      </div>
      {isStartSearch && <SearchLoading />}
      {isShowModal && requiredPlayer && (
        <FindAnotherPlayerModal
          decline={declineHandler}
          retry={retryHandler}
          requiredPlayer={requiredPlayer}
          recommendedBuyPrice={recommendedBuyPrice}
        />
      )}
    </div>
  )
}
