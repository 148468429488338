import React from 'react'

import { Spinner } from 'shared/components/Spinner'
import { MinutesTimer } from 'shared/components/Timer'
import Typography from 'shared/components/Typography'
import { Lot } from 'shared/features/Buy/types'
import { ReactComponent as SuccessIcon } from 'shared/assets/icons/success-rounded.svg'
import { PlayerCard } from 'shared/components/PlayerCard'

import { LotStatus } from '../../constants'
import mapTradeToPlayerCardProps from '../../utils/mapTradeToPlayerCardProps'

import styles from './SellingLoading.module.css'

type Props = {
  lots: Lot[]
}

export const SellingLoading: React.FC<Props> = ({ lots }) => {
  const cards = lots.map(lot => {
    const { status, id, trade } = lot

    const cardProps = mapTradeToPlayerCardProps(trade)

    return (
      <div className={styles.card} key={id}>
        <PlayerCard {...cardProps} />
        {status === LotStatus.sold && <SuccessIcon className={styles.card__status} />}
      </div>
    )
  })

  return (
    <div className={styles.loading}>
      <MinutesTimer timeout={{ hours: 0, minutes: 15, seconds: 0 }} />
      <Spinner />
      <Typography weight="bold" size="xl">
        Время ожидания выкупа может занять несколько минут
      </Typography>
      <div className={styles.loading__cards}>{cards}</div>
      <div className={styles.loading__info}>
        <Typography spacy weight="light">
          В среднем покупка одного игрока занимает три минуты, но иногда бывает чуть дольше
        </Typography>
        <Typography spacy weight="light">
          Если вы считаете, что прошло уже слишком много времени (больше 15 минут), напишите нам.
        </Typography>
      </div>
    </div>
  )
}
