import { differenceInSeconds, isValid, parseISO } from 'date-fns'

export const getDiffTimeInSeconds = (value: string): number => {
  try {
    const date = parseISO(value)
    if (!isValid(date)) {
      return 0
    }
    const diff = differenceInSeconds(date, new Date())

    return diff <= 0 ? 0 : diff
  } catch (error) {
    return 0
  }
}
