import { RequiredPlayer, ShellCardProps } from '../types'

export default (player: RequiredPlayer): ShellCardProps[] => {
  return player.all_versions.map(version => ({
    backgroundSrc: version.shell.image,
    clubImageSrc: version.club.image,
    countryImageSrc: version.country.image,
    rating: version.rating,
    id: version.id,
  }))
}
