import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import FutCoin from 'shared/components/FutCoin'
import InputNumber from 'shared/components/InputNumber'
import Typography from 'shared/components/Typography'
import { ReactComponent as QuestionIcon } from 'shared/assets/icons/question.svg'
import Tooltip from 'shared/components/Tooltip'
import { getInitialBalance, getIsErrorInitialBalance } from 'shared/features/Buy/ducks/selectors'
import { actions as buyActions } from 'shared/features/Buy/ducks'

import styles from './InitialBalance.module.css'

export const InitialBalance = () => {
  const dispatch = useDispatch()
  const initialBalance = useSelector(getInitialBalance)
  const isError = useSelector(getIsErrorInitialBalance)

  const onChange = (value: number) => {
    dispatch(buyActions.setInitialBalance(value))
  }

  const classNames = clsx(styles.initial, [isError && styles.initial__error])

  const tooltipTitle =
    'Чтобы ускорить процесс доставки, пожалуйста, укажите количество монет FIFA, которое у вас уже есть на балансе. Обратите внимание, что если количество монет на вашем счету будет меньше 6000, то максимальная сумма заказа будет ограничена 8000 монетами. Если вам нужно доставить более 8000 монет на свой счет, то в этом случае вы можете сделать два отдельных заказа: первый на сумму до 8000 монет, а затем - на более крупную сумму монет, при условии, что на вашем счету имеется не менее 6000 монет.'

  return (
    <div className={classNames}>
      <Typography size="s">Количество монет FUT на счету</Typography>
      <Tooltip title={tooltipTitle} classNameContent={styles.tooltip}>
        <div className={styles.question}>
          <QuestionIcon className={styles.question__icon} />
        </div>
      </Tooltip>
      <div className={styles.field}>
        <InputNumber
          iconLeft={FutCoin}
          size="xs"
          value={initialBalance}
          onChange={onChange}
          fullWidth
          error={isError}
        />
        {isError && (
          <Typography className={styles.field__error} size="xs" color="error">
            {`Нужно иметь минимум ${MIN_INITIAL_BALANCE} монет на счету`}
          </Typography>
        )}
      </div>
    </div>
  )
}
