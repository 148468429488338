import React from 'react'
import cn from 'clsx'

import styles from './Container.module.css'

interface ContainerProps {
  className?: string
  id?: string
  element?: React.ElementType
}

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  id,
  element: Component = 'div',
}): React.ReactElement => (
  <Component className={cn(styles.container, className)} id={id}>
    {children}
  </Component>
)

export default Container
