import React from 'react'

import { PlayerCard } from 'shared/components/PlayerCard'
import Typography from 'shared/components/Typography'
import { Trade } from 'shared/features/Buy/types'
import mapTradeToPlayerCardProps from 'shared/features/Buy/utils/mapTradeToPlayerCardProps'

import { Modal } from '../Modal'

import styles from './SelectTradeModal.module.css'

type Props = {
  trades: Trade[]
  onSelect: (trade: Trade) => void
}

export const SelectTradeModal: React.FC<Props> = ({ trades, onSelect }) => {
  const cards = trades.map(trade => {
    const playerCardProps = mapTradeToPlayerCardProps(trade)
    const onClickHandle = () => {
      onSelect(trade)
    }
    return (
      <button key={trade.id} onClick={onClickHandle} className={styles.card}>
        <PlayerCard {...playerCardProps} />
      </button>
    )
  })
  return (
    <Modal title="Найденные игроки" className={styles.modal}>
      <Typography color="secondary" size="l" className={styles.subtitle}>
        Какой из этих игроков ваш?
      </Typography>
      <div className={styles.cards}>{cards}</div>
    </Modal>
  )
}
