import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { send } from 'shared/features/WebSocket/WebSocketService'
import consoleError from 'shared/utils/consoleError'

import { BuyStatus } from '../constants'
import { getBuyStatus } from '../ducks/selectors'

type FindAnotherPlayerHook = {
  findAnotherPlayer: () => void
  isStartSearch: boolean
}

export const useFindAnotherPlayer = (): FindAnotherPlayerHook => {
  const buyStatus = useSelector(getBuyStatus)

  const isStartSearch = useMemo(() => buyStatus === BuyStatus.preparing, [buyStatus])

  const findAnotherPlayer = async () => {
    try {
      await send<boolean>(WebSocketMessageService.getFindAnotherPlayerMessage())
    } catch (error) {
      consoleError(error)
    }
  }

  return {
    findAnotherPlayer,
    isStartSearch,
  }
}
