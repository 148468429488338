import React from 'react'
import cn from 'clsx'

import { ShellCardProps } from '../../types'

import { ShellCard } from './ShellCard'
import styles from './ShellCards.module.css'

type Props = {
  cards: ShellCardProps[]
  className?: string
  playerId: number
}

export const ShellCards: React.FC<Props> = ({ cards, className, playerId }) => {
  const list = cards.map(card => {
    const isActive = playerId === card.id
    return <ShellCard key={card.id} isActive={isActive} {...card} />
  })

  return <div className={cn(styles.cards, className)}>{list}</div>
}
