import { useEffect, useState } from 'react'

import useInterval from './useInterval'

export const useCountdown = (count: number): number => {
  const [time, setTime] = useState(() => count)
  const intervalId = useInterval(() => {
    setTime(prev => (prev <= 0 ? 0 : prev - 1))
  })

  useEffect(() => {
    if (time === 0) {
      clearInterval(intervalId)
    }
  }, [time])

  return time
}
