import React from 'react'

import Typography from 'shared/components/Typography'

import DocumentPage from '../../components/DocumentPage/DocumentPage'

import styles from './Terms.module.css'
import { termBlocks } from './constants'

const blocks = termBlocks.map(termBlock => (
  <div className={styles.infoBlock} key={termBlock.subtitle}>
    <Typography size="l" weight="bold">
      {termBlock.subtitle}
      {termBlock.paragraphs.map(paragraph => (
        <Typography key={paragraph} weight="light" size="l" className={styles.paragraph}>
          {paragraph}
        </Typography>
      ))}
    </Typography>
  </div>
))

const Terms: React.FC = () => {
  return (
    <DocumentPage title="Пользовательское соглашение">
      <Typography weight="light" size="l" className={styles.paragraph}>
        Если вы продолжаете просматривать и использовать этот веб-сайт, вы соглашаетесь соблюдать следующее
        пользовательское соглашение, которое вместе с нашей политикой конфиденциальности регулируют отношения
        www.futtycoin.ru с вами в отношении этого веб-сайта. Если вы не согласны с какой-либо частью этих условий,
        пожалуйста, не используйте наш веб-сайт.
      </Typography>
      <Typography weight="light" size="l" className={styles.paragraph}>
        Использование данного веб-сайта допускается при соблюдении следующих пользовательского соглашения: Содержание
        страниц этого сайта предназначено только для вашей общей информации и использования. Он может быть изменен без
        предварительного уведомления.
      </Typography>
      <Typography weight="light" size="l" className={styles.paragraph}>
        Ни мы, ни третьи стороны не предоставляем никаких гарантий или гарантий относительно точности, своевременности,
        производительности, полноты или пригодности информации и материалов, найденных или предлагаемых на этом
        веб-сайте, для какой-либо конкретной цели. Вы признаете, что такая информация и материалы могут содержать
        неточности или ошибки, и мы прямо исключаем ответственность за любые такие неточности или ошибки в максимально
        возможной степени, разрешенной законом.
      </Typography>
      <Typography weight="light" size="l" className={styles.paragraph}>
        Ваше использование любой информации или материалов на этом веб-сайте полностью зависит от вашего собственного
        риска, за которое мы не несем ответственности. Вы несете ответственность за то, чтобы любые продукты, услуги или
        информация, доступная через этот веб-сайт, соответствовали вашим конкретным требованиям.
      </Typography>
      {blocks}
    </DocumentPage>
  )
}

export default Terms
