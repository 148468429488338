import React from 'react'
import cn from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import Button, { ButtonColor, ButtonSize } from 'shared/components/Button'
import { BuyPlatform } from 'shared/features/Buy/constants'
import { actions as buyActions } from 'shared/features/Buy/ducks'
import { getBuyPlatform } from 'shared/features/Buy/ducks/selectors'
import { ButtonConsole } from 'shared/components/ButtonConsole/ButtonConsole'
import { ReactComponent as PcIcon } from 'shared/assets/icons/platforms/pc-mac.svg'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import styles from './BuyForm.module.css'

export const ExchangeButtons = () => {
  const dispatch = useDispatch()

  const { isMobile } = useWindowSize()

  const buttonSize: ButtonSize = isMobile ? ButtonSize.s : ButtonSize.l

  const currentPlatform = useSelector(getBuyPlatform)

  const changePlatform = (platform: BuyPlatform) => () => {
    dispatch(buyActions.setPlatform(platform))
  }

  return (
    <>
      <Button
        className={cn({
          [styles.activeChangeButton]: BuyPlatform.Pc === currentPlatform,
        })}
        onClick={changePlatform(BuyPlatform.Pc)}
        icon={PcIcon}
        color={BuyPlatform.Pc === currentPlatform ? ButtonColor.primary : ButtonColor.gray}
        outline
        fullWidth
        size={buttonSize}
      >
        Pc
      </Button>
      <ButtonConsole
        className={cn(styles.buttons_console, {
          [styles.activeChangeButton]: BuyPlatform.Console === currentPlatform,
        })}
        onClick={changePlatform(BuyPlatform.Console)}
        color={BuyPlatform.Console === currentPlatform ? ButtonColor.primary : ButtonColor.gray}
        outline
        fullWidth
        size={buttonSize}
      />
    </>
  )
}
