import React from 'react'
import cn from 'clsx'

import { InfoCard, InfoCardProps } from 'shared/components/InfoCard'

import styles from './InfoCards.module.css'

type Props = {
  cards: InfoCardProps[]
  className?: string
}

export const InfoCards: React.FC<Props> = ({ cards, className }) => {
  const list = cards.map(card => <InfoCard {...card} key={card.label} />)
  return <div className={cn(styles.cards, className)}>{list}</div>
}
