import React from 'react'

import { Spinner } from 'shared/components/Spinner'
import Typography from 'shared/components/Typography'

import styles from './Payment.module.css'

interface Props {
  text: string
}

export const SendMessagesLoading: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.loading}>
      <Spinner />
      <Typography size="xl" weight="bold">
        {text}
      </Typography>
    </div>
  )
}
