import { useState } from 'react'

import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { send } from 'shared/features/WebSocket/WebSocketService'
import consoleError from 'shared/utils/consoleError'

import { Player } from '../types'

type SearchPlayerHook = {
  players: Player[]
  sendSearchPlayer: (query: string) => void
  resetPlayers: () => void
  isStartSearchPlayers: boolean
}

export default (): SearchPlayerHook => {
  const [players, setPlayers] = useState<Player[]>([])
  const [isStartSearchPlayers, setIsStartSearchPlayers] = useState<boolean>(false)

  const resetPlayers = () => {
    setPlayers([])
  }

  const sendSearchPlayer = async (query: string) => {
    resetPlayers()
    if (!query) {
      setIsStartSearchPlayers(false)
      return
    }

    try {
      setIsStartSearchPlayers(true)
      const players = await send<Player[]>(WebSocketMessageService.getSearchPlayerMessage(query))
      setPlayers(players)
      setIsStartSearchPlayers(false)
    } catch (error) {
      setIsStartSearchPlayers(false)
      consoleError(error)
    }
  }

  return { sendSearchPlayer, resetPlayers, players, isStartSearchPlayers }
}
