export const isDisabledTabButton = (isActive: boolean, isViewed: boolean): boolean => {
  if (isActive) {
    return false
  }

  if (!isActive && !isViewed) {
    return true
  }

  return false
}
