import React from 'react'

import Button from 'shared/components/Button'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import { InstructionTab } from '../../typings'

import { tabs } from './InstructionTabs'
import styles from './InstructionTabs.module.css'

type Props = {
  onClick: (tab: InstructionTab) => void
  checked: string
}

export const TabButtons: React.FC<Props> = ({ onClick, checked }) => {
  const { isMobile } = useWindowSize()
  return (
    <div className={styles.buttons}>
      {tabs.map(tab => {
        const isActive = checked === tab.name

        return (
          <Button
            color="darkGray"
            outline={!isActive}
            size="s"
            fullWidth={isMobile}
            onClick={() => {
              onClick(tab)
            }}
            key={tab.name}
            className={styles.button}
          >
            {tab.name}
          </Button>
        )
      })}
    </div>
  )
}
