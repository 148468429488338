import { Timeout } from './types'

export const timeoutToSeconds = ({ hours, minutes, seconds }: Timeout): number => {
  return hours * 3600 + minutes * 60 + seconds
}

export const secondsToTimeout = (value: number): Timeout => {
  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value % 3600) / 60)
  const seconds = value % 60

  return {
    hours,
    minutes,
    seconds,
  }
}

export const formatTime = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`
}
