import React from 'react'
import cn from 'clsx'

import Typography from '../Typography'

import styles from './FormItem.module.css'

interface FormItemProps {
  label: string
  className?: string
  htmlFor?: string
  error?: boolean
  errorText?: string
}

const FormItem: React.FC<FormItemProps> = ({
  label,
  htmlFor,
  error,
  errorText,
  children,
  className,
}): React.ReactElement => (
  <div
    className={cn(
      styles.formItem,
      {
        [styles.formItem__error]: error,
      },
      className,
    )}
  >
    <label className={styles.formItem__label} htmlFor={htmlFor}>
      {label}
    </label>
    <div className={styles.input}>
      {children}
      {error && (
        <Typography ellipsis size="xs" color="error" className={styles.error}>
          {errorText}
        </Typography>
      )}
    </div>
  </div>
)

export default FormItem
