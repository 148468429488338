import { AppState } from 'shared/store/reducer'
import { PaginationParams } from 'shared/types'

import { Review, ReviewsFilter } from '../types'

export const getReviews = (state: AppState): Review[] => state.reviews.reviews
export const getReviewsIsLoading = (state: AppState): boolean => state.reviews.reviewsIsLoading
export const getReviewsPagination = (state: AppState): PaginationParams => state.reviews.reviewsPagination
export const getReviewsFilter = (state: AppState): ReviewsFilter => state.reviews.reviewsFilter
export const getCreateReviewIsLoading = (state: AppState): boolean => state.reviews.createReviewIsLoading
export const getCreateReviewIsSuccess = (state: AppState): boolean => state.reviews.createReviewIsSuccess
