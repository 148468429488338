export const privacyParagraphs = [
  '1. Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.',
  '2. Согласие дается на обработку следующих моих персональных данных: Персональные данные, не являющиеся специальными или биометрическими: номера контактных телефонов; адреса электронной почты; пользовательские данные (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес).',
  '3. Персональные данные не являются общедоступными.',
  '4. Цель обработки персональных данных: обработка входящих запросов физических лиц с целью оказания консультирования; аналитики действий физического лица на веб-сайте и функционирования веб-сайта; проведение рекламных и новостных рассылок.',
  '5. Основанием для обработки персональных данных является: ст. 24 Конституции Российской Федерации; ст.6 Федерального закона No152-ФЗ «О персональных данных»; настоящее согласие на обработку персональных данных.',
  '6. В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; передача (распространение, предоставление, доступ); блокирование; удаление; уничтожение.',
  '7. Персональные данные обрабатываются до отписки физического лица от рекламных и новостных рассылок. Также обработка персональных данных может быть прекращена по запросу субъекта персональных данных. Хранение персональных данных, зафиксированных на бумажных носителях осуществляется согласно Федеральному закону No125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и архивного хранения.',
  '8. Согласие может быть отозвано субъектом персональных данных или его представителем путем направления письменного заявления www.futtycoin.ru по электронному адресу futlab@yandex.ru',
  '9. В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных данных www.futtycoin.ru вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2-11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона No152-ФЗ «О персональных данных» от 27.07.2006 г.',
  '10. Оплатить заказ можно банковскими картами Visa, Master Card, «Мир» или через платежные системы Apple Pay. Чтобы оплатить покупку, вы будете перенаправлены на сервер платежной системы Unitpay, на котором нужно ввести необходимые данные. При оплате банковской картой безопасность платежей гарантирует процессинговый центр Unitpay.',
  '10.1. Платежная система Unitpay обладает подтвержденным сертификатом соответствия требованиям стандарта PCI DSS в части хранения, обработки и передачи данных держателей карт. Стандарт безопасности банковских карт PCI DSS поддерживается международными платежными системами, включая MasterCard и Visa, Inc. Система Unitpay также является участником программы непрерывного соответствия Compliance Control PCI DSS Compliance Process (P.D.C.P.). Ваши конфиденциальные данные, необходимые для оплаты (реквизиты карты, регистрационные данные и др.), не поступают в интернет-магазин — их обработка производится на стороне процессингового центра Unitpay и полностью защищена',
  '10.2. Никто, в том числе наш интернет-магазин «FuttyCoin», не может получить данные вашей банковской карты или иные данные, необходимые для осуществления платежа.',
  '11. Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в п.7 и п.8 данного Согласия',
]
