import React from 'react'

import { Spinner } from 'shared/components/Spinner'

import styles from './Step1.module.css'

export const SearchLoading = () => (
  <div className={styles.loading}>
    <Spinner />
  </div>
)
