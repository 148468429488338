import React from 'react'
import { useSelector } from 'react-redux'

import Container from 'shared/core/layouts/Container'
import { Divider } from 'shared/components/Divider'
import Typography, { TypographySize } from 'shared/components/Typography'
import { getExchangeRates } from 'shared/features/Buy/ducks/selectors'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import { BuyForm } from '../BuyForm'

import styles from './BuyBlock.module.css'

export const BuyBlock = () => {
  const exchangeRates = useSelector(getExchangeRates)

  const { isMobile } = useWindowSize()

  const titleSize: TypographySize = isMobile ? TypographySize.xxxl : TypographySize.xxxxl
  const descriptionSize: TypographySize = isMobile ? TypographySize.s : TypographySize.l

  return (
    <Container className={styles.buy}>
      <Divider className={styles.divider} />
      <div className={styles.body}>
        <div className={styles.description}>
          <div className={styles.description__title}>
            <div className={styles.description__title_text}>
              <Typography color="brand" size={titleSize} weight="bold" uppercase>
                Купить монеты
              </Typography>
              <Typography size={titleSize} weight="bold" uppercase>
                FIFA 23
              </Typography>
            </div>
            {isMobile && <img src="/images/coins.png" alt="coins" className={styles.description__title_img} />}
          </div>
          <div className={styles.description__body}>
            <Typography className={styles.description__body_text} weight="light" size={descriptionSize} spacy>
              Сервис FuttyCoin это быстрый и надежный способ доставки игровых монет
            </Typography>
            {!isMobile && <img src="/images/coins.png" alt="coins" className={styles.description__body_img} />}
          </div>
        </div>
        <div className={styles.form}>{exchangeRates && <BuyForm />}</div>
      </div>
    </Container>
  )
}
