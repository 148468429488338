import React from 'react'
import { useSelector } from 'react-redux'

import { getRequiredPlayer, getRequiredPlayersCount } from 'shared/features/Buy/ducks/selectors'

import { Step } from '../Step'

import { RequiredPlayer } from './RequiredPlayer'
import { NoRequiredPlayer } from './NoRequiredPlayer'

export const Step1 = () => {
  const requiredPlayersCount = useSelector(getRequiredPlayersCount)
  const requiredPlayer = useSelector(getRequiredPlayer)

  const title =
    requiredPlayersCount === 1 ? 'Купите игрока на трансферном рынке' : 'Купите 2 игроков на трансферном рынке'

  return (
    <Step title={title}>
      {requiredPlayer ? <RequiredPlayer requiredPlayer={requiredPlayer} /> : <NoRequiredPlayer />}
    </Step>
  )
}
