import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { FormPayload } from 'shared/features/Buy/components/Steps/Step3/SendForm'
import { Player, RequiredPlayer, RequiredPlayersCount, Trade } from 'shared/features/Buy/types'

import { getIsStartSelling } from '../ducks/selectors'

import useClearLots from './useClearLots'
import useSearchPlayer from './useSearchPlayer'
import useSearchAuctions from './useSearchAuctions'
import useNextStep from './useNextStep'

type SearchHook = {
  players: Player[]
  auctions: Trade[]
  selectTrades: Trade[]
  onSubmit: (payload: FormPayload) => void
  sendSearchAuctions: (player: Player) => void
  sendSearchPlayer: (query: string) => void
  sendSelectTrade: (trade: Trade) => void
  sendNextStep: () => void
  reset: () => void
  clearLots: () => void
  currentSearchAuctionsPlayer: Player | null
  isStartSearchPlayers: boolean
  isShowSearchLoading: boolean
  isShowSellingLoading: boolean
  isShowPlayersNotFoundModal: boolean
  isShowPlayersConfirmModal: boolean
  isShowSelectTradeModal: boolean
}

export default (requiredPlayersCount: RequiredPlayersCount, requiredPlayer: RequiredPlayer | null): SearchHook => {
  const isStartSelling = useSelector(getIsStartSelling)

  const { sendClearLots } = useClearLots()
  const { players, resetPlayers, sendSearchPlayer, isStartSearchPlayers } = useSearchPlayer()
  const {
    auctions,
    selectTrades,
    resetAuctions,
    sendSearchAuctions: searchAuctions,
    sendSelectTrade: selectTrade,
    isStartSearchAuctions,
    isPlayersNotFound,
    isAllPlayersFound,
    isNeedToSelectTrade,
  } = useSearchAuctions(requiredPlayersCount)
  const { sendNextStep } = useNextStep()

  const [currentSearchAuctionsPlayer, setCurrentSearchAuctionsPlayer] = useState<Player | null>(requiredPlayer)

  // Modals
  const [isShowSearchLoading, setIsShowSearchLoading] = useState<boolean>(false)
  const [isShowSellingLoading, setIsShowSellingLoading] = useState<boolean>(false)
  const [isShowPlayersNotFoundModal, setIsShowPlayersNotFoundModal] = useState<boolean>(false)
  const [isShowPlayersConfirmModal, setIsShowPlayersConfirmModal] = useState<boolean>(false)
  const [isShowSelectTradeModal, setIsShowSelectTradeModal] = useState<boolean>(false)

  const resetModals = () => {
    setIsShowSearchLoading(false)
    setIsShowSellingLoading(false)
    setIsShowPlayersNotFoundModal(false)
    setIsShowPlayersConfirmModal(false)
    setIsShowSelectTradeModal(false)
  }

  const sendSearchAuctions = (player: Player) => {
    const { id } = player
    setIsShowPlayersNotFoundModal(false)
    setCurrentSearchAuctionsPlayer(player)
    resetPlayers()
    searchAuctions(id)
  }

  const sendSelectTrade = (trade: Trade) => {
    const { id, player } = trade
    setIsShowSelectTradeModal(false)
    setCurrentSearchAuctionsPlayer(player)
    selectTrade(id)
  }

  useEffect(() => {
    if (requiredPlayer) {
      sendSearchAuctions(requiredPlayer)
    }
  }, [])

  useEffect(() => {
    if (!isStartSearchAuctions) {
      setIsShowSearchLoading(false)
      return
    }

    if (isStartSearchAuctions && currentSearchAuctionsPlayer) {
      resetModals()
      setIsShowSearchLoading(true)
    }
  }, [isStartSearchAuctions, currentSearchAuctionsPlayer])

  useEffect(() => {
    if (isStartSelling) {
      resetModals()
      setIsShowSellingLoading(true)
    }
  }, [isStartSelling])

  useEffect(() => {
    if (isAllPlayersFound && !isShowSellingLoading) {
      resetModals()
      setIsShowPlayersConfirmModal(true)
    }
  }, [isAllPlayersFound, isShowSellingLoading])

  useEffect(() => {
    if (isPlayersNotFound && currentSearchAuctionsPlayer) {
      resetModals()
      setIsShowPlayersNotFoundModal(true)
    }
  }, [isPlayersNotFound, currentSearchAuctionsPlayer])

  useEffect(() => {
    if (isNeedToSelectTrade) {
      resetModals()
      setIsShowSelectTradeModal(true)
    }
  }, [isNeedToSelectTrade])

  const onSubmit = () => {
    // TODO implement logic
    // resetAuctions()
    // setIsStartSearchAuctions(true)
  }

  const reset = () => {
    setCurrentSearchAuctionsPlayer(null)
    setIsShowPlayersNotFoundModal(false)
  }

  const clearLots = () => {
    setCurrentSearchAuctionsPlayer(null)
    resetAuctions()
    setIsShowPlayersConfirmModal(false)
    sendClearLots()
  }

  return {
    players,
    auctions,
    sendSearchAuctions,
    sendSearchPlayer,
    sendSelectTrade,
    sendNextStep,
    reset,
    clearLots,
    onSubmit,
    currentSearchAuctionsPlayer,
    isStartSearchPlayers,
    isShowSearchLoading,
    isShowSellingLoading,
    isShowPlayersConfirmModal,
    isShowPlayersNotFoundModal,
    isShowSelectTradeModal,
    selectTrades,
  }
}
