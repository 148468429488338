import React from 'react'
import { Formik, FormikProps } from 'formik'
import { useSelector } from 'react-redux'
import round from 'lodash.round'

import { ReactComponent as FutoPointIcon } from 'shared/assets/icons/futo-point.svg'
import { ReactComponent as RblIcon } from 'shared/assets/icons/currency_rbl.svg'
import InputNumber from 'shared/components/InputNumber'
import Typography from 'shared/components/Typography'
import { getExchangeFutopointRate, getOrderId, getOrderPrice } from 'shared/features/Buy/ducks/selectors'
import Button from 'shared/components/Button'
import { InputSlider } from 'shared/components/InputSlider'
import { getTotalPoints, getUserEmail } from 'shared/features/Profile/ducks/selectors'
import { Switch } from 'shared/components/Switch'
import FormItem from 'shared/components/FormItem'
import usePayment from 'shared/features/Buy/hooks/usePayment'
import Input from 'shared/components/Input'

import styles from './Payment.module.css'
import validationSchema from './validationSchema'

export type FormPayload = {
  ftp: number
  money: number
  email?: string
  isShowEmail: boolean
  isVisibleInput: boolean
}

export const SendForm = () => {
  const { handlePayment, paymentIsLoading } = usePayment()

  const exchangeRate = useSelector(getExchangeFutopointRate)
  const orderId = useSelector(getOrderId)
  const price = useSelector(getOrderPrice)
  const totalPoints = useSelector(getTotalPoints)
  const userEmail = useSelector(getUserEmail)

  const futopointPrice = price * exchangeRate
  const maxFtpAmount = totalPoints >= futopointPrice ? Math.ceil(futopointPrice) : totalPoints

  const handleSubmit = ({ ftp, money, email, isShowEmail }: FormPayload) => {
    handlePayment({
      ftp: ftp || null,
      money: money || null,
      deliveryId: orderId,
      email: isShowEmail && email ? email : null,
    })
  }

  const initialValues: FormPayload = {
    ftp: 0,
    money: price,
    email: userEmail,
    isShowEmail: true,
    isVisibleInput: false,
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({
        handleSubmit,
        values: { money, ftp, email, isShowEmail, isVisibleInput },
        setValues,
        handleChange,
        errors,
        touched,
      }: FormikProps<FormPayload>): React.ReactElement => {
        const { email: emailError } = errors

        const onChange = (value: number) => {
          const ftpValue = value > maxFtpAmount ? maxFtpAmount : value
          const moneyValue = ftpValue >= futopointPrice ? 0 : round((futopointPrice - ftpValue) / exchangeRate, 2)

          setValues(
            {
              ftp: ftpValue,
              isShowEmail: moneyValue !== 0,
              money: moneyValue,
              email,
              isVisibleInput,
            },
            true,
          )
        }

        const onBlurHandler = (): void => {
          if (money === 0 || money >= 1) {
            return
          }

          const ftpValue = (price - 1) * exchangeRate
          setValues(
            {
              ftp: ftpValue,
              isShowEmail: true,
              money: 1,
              email,
              isVisibleInput,
            },
            true,
          )
        }

        const switchHandle = (checked: boolean) => {
          setValues(
            {
              ftp: 0,
              isShowEmail: true,
              money: price,
              email,
              isVisibleInput: checked,
            },
            true,
          )
        }

        const onChangeSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(Number.parseInt(e.target.value, 10))
        }

        const isEmailError = Boolean(emailError && touched.email && isShowEmail)

        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.switch}>
              <Switch onChange={switchHandle} checked={isVisibleInput} name="isVisibleInput" />
              <Typography weight="normal" color="secondary">
                Использовать бонусы FTP
              </Typography>
            </div>
            {isVisibleInput && (
              <div className={styles.input}>
                <InputNumber
                  size="s"
                  iconLeft={FutoPointIcon}
                  onChange={onChange}
                  value={ftp}
                  onBlur={onBlurHandler}
                  fullWidth
                />
                <InputSlider
                  className={styles.slider}
                  min={0}
                  max={maxFtpAmount}
                  step={1}
                  onChange={onChangeSlider}
                  onBlur={onBlurHandler}
                  value={ftp}
                />
              </div>
            )}
            <Typography weight="medium" className={styles.total}>
              Итого к оплате:
            </Typography>
            <Typography weight="bold" size="xl" className={styles.money}>
              {money}
              <RblIcon className={styles.money_currency} />
            </Typography>
            {isShowEmail && (
              <FormItem
                label="Укажите адрес электронной почты для отправки чека *"
                error={isEmailError}
                errorText={emailError}
                className={styles.email}
              >
                <Input
                  value={email}
                  onChange={handleChange}
                  error={isEmailError}
                  name="email"
                  size="s"
                  className={styles.email__input}
                />
              </FormItem>
            )}
            <Button
              className={styles.button}
              type="submit"
              fullWidth
              disabled={paymentIsLoading || isEmailError}
              loading={paymentIsLoading}
            >
              Оплатить
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}
