import React, { useCallback } from 'react'
import cn from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import Button, { ButtonColor } from 'shared/components/Button'
import { ReactComponent as SuccessIcon } from 'shared/assets/icons/success-rounded.svg'

import { actions } from '../../ducks'
import { getActiveStep, getBuyStatus, getSteps } from '../../ducks/selectors'
import { getIconByTabName } from '../../utils/getIconByTabName'
import { isDisabledTabButton } from '../../utils/isDisabledTabButton'
import { BuyStatus, StepName } from '../../constants'

import styles from './Tabs.module.css'

type Props = {
  className?: string
}

export const Tabs: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch()
  const steps = useSelector(getSteps)
  const activeStep = useSelector(getActiveStep)
  const buyStatus = useSelector(getBuyStatus)

  const onClickHandler = useCallback(
    (stepName: StepName) => () => {
      if (buyStatus === BuyStatus.complete || buyStatus === BuyStatus.selling) {
        return
      }

      dispatch(actions.setActiveStep(stepName))
    },
    [buyStatus],
  )

  const tabs = steps.map(step => {
    const isActive = step.name === activeStep
    const color: ButtonColor = step.isViewed && !isActive ? ButtonColor.success : ButtonColor.primary
    const disabled = isDisabledTabButton(isActive, step.isViewed)
    const icon = step.isViewed ? SuccessIcon : getIconByTabName(step.name, isActive)
    return (
      step.withTabButton && (
        <Button
          onClick={onClickHandler(step.name)}
          icon={icon}
          color={color}
          outline
          fullWidth
          size="s"
          key={step.name.toString()}
          disabled={disabled}
        >
          {step.tabButtonName}
        </Button>
      )
    )
  })

  return <div className={cn(styles.tabs, className)}>{tabs}</div>
}
