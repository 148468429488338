import { useEffect, useState } from 'react'

import { Breakpoints } from 'shared/constants/breakpoints'

type WindowSize = {
  width: number
  height: number
}

type WindowSizeHook = {
  windowSize: WindowSize | null
  isMobile: boolean
}

export const useWindowSize = (): WindowSizeHook => {
  const [windowSize, setWindowSize] = useState<WindowSize | null>(null)

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window
    function handleResize() {
      setWindowSize({
        height,
        width,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    windowSize,
    isMobile: !!windowSize && windowSize.width < Breakpoints.Tablet,
  }
}
