import React from 'react'

import { ReactComponent as ErrorIcon } from 'shared/assets/icons/error.svg'
import Button from 'shared/components/Button'
import Typography from 'shared/components/Typography'

import { Modal } from '../Modal'

import styles from './OrderCanceledModal.module.css'

type Props = {
  confirm: () => void
}

export const OrderCanceledModal: React.FC<Props> = ({ confirm }) => (
  <Modal size="large">
    <ErrorIcon className={styles.icon} />
    <Typography size="m" color="secondary" weight="light" className={styles.description}>
      Заказ закрыт в следствие ошибки на стороне Electronic Arts. Все денежные средства будут конвертированы и
      возвращены на ваш бонусный счет FTP. Все последующие заказы вы также сможете оплатить бонусами FTP.
    </Typography>
    <Button className={styles.button} onClick={confirm} outline uppercase>
      Ok
    </Button>
  </Modal>
)
