import React from 'react'

import Button from 'shared/components/Button'
import Typography from 'shared/components/Typography'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import { RequiredPlayer } from 'shared/features/Buy/types'
import formatNumber from 'shared/utils/formatNumber'

import { Modal } from '../Modal'

import styles from './FindAnotherPlayerModal.module.css'

type Props = {
  retry: () => void
  decline: () => void
  requiredPlayer: RequiredPlayer
  recommendedBuyPrice: number
}

export const FindAnotherPlayerModal: React.FC<Props> = ({ retry, decline, requiredPlayer, recommendedBuyPrice }) => {
  const {
    display_name: name,
    rating,
    shell: { tag },
  } = requiredPlayer
  return (
    <Modal title="Игрок не найден">
      <SearchIcon className={styles.icon} />
      <Typography weight="light" spacy>
        {`Не можете найти ${name} (${rating} ${tag}) за ${formatNumber(recommendedBuyPrice)} монет`}
      </Typography>
      <Typography weight="light" spacy className={styles.description}>
        Мы еще раз проверим цены на рынке или предложим купить другого игрока
      </Typography>
      <div className={styles.buttons}>
        <Button outline onClick={decline} color="darkGray">
          Назад
        </Button>
        <Button onClick={retry}>Проверить</Button>
      </div>
    </Modal>
  )
}
