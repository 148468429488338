import React from 'react'

import { useCountdown } from 'shared/hooks/useCountdown'

import Typography, { TypographyProps } from '../Typography'

import { Timeout } from './types'
import { formatTime, secondsToTimeout, timeoutToSeconds } from './utils'

type Props = {
  timeout: Timeout
} & TypographyProps

export const MinutesTimer: React.FC<Props> = ({
  timeout,
  spacy,
  size = 'xl',
  weight = 'bold',
  color = 'brand',
  ...rest
}) => {
  const time = useCountdown(timeoutToSeconds(timeout))

  const { minutes, seconds } = secondsToTimeout(time)

  return (
    <Typography spacy={spacy} color={color} size={size} weight={weight} {...rest}>
      {`${formatTime(minutes)} : ${formatTime(seconds)}`}
    </Typography>
  )
}
