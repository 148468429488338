import React from 'react'

import { useCountdown } from 'shared/hooks/useCountdown'

import Typography, { TypographyProps } from '../Typography'

import { Timeout } from './types'
import { formatTime, secondsToTimeout, timeoutToSeconds } from './utils'

type Props = {
  timeout: Timeout
} & TypographyProps

export const HoursTimer: React.FC<Props> = ({ timeout, ...rest }) => {
  const time = useCountdown(timeoutToSeconds(timeout))

  const { hours, minutes, seconds } = secondsToTimeout(time)

  return <Typography {...rest}>{`${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`}</Typography>
}
