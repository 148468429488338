import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIsPreloadInfoCompleted } from 'shared/features/Buy/ducks/selectors'
import { actions as buyActions } from 'shared/features/Buy/ducks'

import { Main } from '../Main'
import { Preloader } from '../Preloader'
import { Reviews } from '../Reviews'

import styles from './Content.module.css'

export const Content = () => {
  const dispatch = useDispatch()
  const isPreloadInfoCompleted = useSelector(getIsPreloadInfoCompleted)

  useEffect(
    () => () => {
      dispatch(buyActions.setIsPreloadInfoCompleted(false))
    },
    [],
  )

  if (!isPreloadInfoCompleted) {
    return <Preloader />
  }

  return (
    <div className={styles.root}>
      <Main />
      <Reviews />
    </div>
  )
}
