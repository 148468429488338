import React, { forwardRef, useCallback, useMemo, useState } from 'react'

import Input from 'shared/components/Input'
import onlyDigits from 'shared/utils/onlyDigits'
import formatNumber from 'shared/utils/formatNumber'

import { InputProps } from '../Input/types'

interface InputNumberProps extends Omit<InputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: number
  onChange?(value: number, formatted: string, name?: string): void
  onBlur?(value: number, formatted: string, name?: string): void
}

const InputNumber = forwardRef<HTMLInputElement & HTMLTextAreaElement, InputNumberProps>(function InputNumber(
  props,
  ref,
): React.ReactElement {
  const { value: initialValue = 0, name, onChange, onBlur, ...otherProps } = props

  const [value, setValue] = useState<number>(() => initialValue)
  const formatted: string = useMemo((): string => formatNumber(initialValue), [initialValue])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value: valueEvent } = event.target
      const nextValue = parseInt(onlyDigits(valueEvent), 10) || 0

      if (onChange) {
        onChange(nextValue, formatted, name)
      }
      setValue(nextValue)
    },
    [name, onChange, value],
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      const { value: valueEvent } = event.target
      const nextValue = parseInt(onlyDigits(valueEvent), 10) || 0

      if (onBlur) {
        onBlur(nextValue, formatNumber(value), name)
        return
      }
      setValue(nextValue)
    },
    [name, onBlur, value],
  )

  return <Input {...otherProps} name={name} value={formatted} onChange={handleChange} onBlur={handleBlur} ref={ref} />
})

export default InputNumber
