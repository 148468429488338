import React from 'react'

import Typography from 'shared/components/Typography'

import { PaymentIcons } from './PaymentIcons'
import styles from './Payment.module.css'

export const PaymentDescription = () => (
  <div className={styles.description}>
    <PaymentIcons className={styles.description__icons} />
    <Typography weight="medium" color="secondary" size="xs">
      Безопасная оплата через сайт банка-партнера.
    </Typography>
    <Typography weight="medium" color="secondary" size="xs">
      Безопасность работы подтверждена сертификацией PCI DSS Level 3.
    </Typography>
  </div>
)
