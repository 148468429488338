import React from 'react'

import Typography from 'shared/components/Typography'

import DocumentPage from '../../components/DocumentPage'

import styles from './Privacy.module.css'
import { privacyParagraphs } from './constants'

const paragraphs = privacyParagraphs.map(paragraph => (
  <Typography key={paragraph} weight="light" size="m" className={styles.paragraph}>
    {paragraph}
  </Typography>
))

const Privacy: React.FC = () => {
  return (
    <DocumentPage title="Политика конфиденциальности">
      <Typography size="l" weight="bold" className={styles.subtitle}>
        СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ КЛИЕНТОВ - ФИЗИЧЕСКИХ ЛИЦ
      </Typography>
      <Typography weight="light" size="m">
        Пользователь, регистрируясь на интернет-сайте www.futtycoin.ru, принимает настоящее Согласие на обработку
        персональных данных (далее — Согласие). Действуя свободно, своей волей и в своем интересе, а также подтверждая
        свою дееспособность, Пользователь дает свое согласие интернет-сайту www.futtycoin.ru на обработку своих
        персональных данных со следующими условиями:
      </Typography>

      <div className={styles.list}>{paragraphs}</div>
    </DocumentPage>
  )
}

export default Privacy
