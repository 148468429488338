import React from 'react'

import Button from 'shared/components/Button'
import Typography from 'shared/components/Typography'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'

import { Modal } from '../Modal'

import styles from './PlayersNotFoundModal.module.css'

type Props = {
  retry: () => void
  decline: () => void
}

export const PlayersNotFoundModal: React.FC<Props> = ({ retry, decline }) => (
  <Modal title="Игрок не найден на рынке">
    <SearchIcon className={styles.icon} />
    <Typography spacy color="secondary">
      Убедитесь, что ваш игрок выставлен правильно
    </Typography>
    <div className={styles.buttons}>
      <Button outline onClick={decline} color="darkGray">
        Ок
      </Button>
      <Button onClick={retry}>Проверить</Button>
    </div>
  </Modal>
)
