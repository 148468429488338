export default (price: number, fixed = 1): string => {
  if (!price) return '0'
  const thousand = 1000
  const million = 1000000

  const getFixedValue = (value: number): string => {
    const division: string = value.toFixed(1).split('.')[1]
    const isDivision: boolean = !!division && +division[0] !== 0

    return value.toFixed(+isDivision && fixed)
  }

  if (Math.abs(price) >= million) return `${getFixedValue(price / million)} m`
  if (Math.abs(price) >= thousand) return `${getFixedValue(price / thousand)} k`

  return price.toFixed(0)
}
