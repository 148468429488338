import React from 'react'

import Typography from 'shared/components/Typography'
import { RequiredPlayersCount, Trade } from 'shared/features/Buy/types'
import mapTradeToPlayerCardProps from 'shared/features/Buy/utils/mapTradeToPlayerCardProps'
import { PlayerCard } from 'shared/components/PlayerCard'

import styles from './Step3.module.css'

type Props = {
  requiredPlayersCount: RequiredPlayersCount
  currentPlayersCount: number
  auctions: Trade[]
}

export const SearchInfo: React.FC<Props> = ({ auctions, currentPlayersCount, requiredPlayersCount }) => {
  const foundedAuctions = auctions.map(auction => {
    const { id, ...cardProps } = mapTradeToPlayerCardProps(auction)
    return <PlayerCard {...cardProps} key={id} id={id} />
  })

  return (
    <div className={styles.searchInfo}>
      <Typography weight="light" size="xl">
        {`Ваши выбранные игроки (${currentPlayersCount}/${requiredPlayersCount})`}
      </Typography>
      {!currentPlayersCount ? (
        <Typography weight="light" size="l">
          Вы еще не выбрали игроков
        </Typography>
      ) : (
        <div className={styles.searchInfo__auctions}>{foundedAuctions}</div>
      )}
    </div>
  )
}
