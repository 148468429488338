import React from 'react'

import styles from './BuyForm.module.css'
import { InputInfo } from './InputInfo'
import { ExchangeButtons } from './ExchangeButtons'
import { SendButton } from './SendButton'

export const BuyForm = () => {
  return (
    <div className={styles.form}>
      <InputInfo />
      <div className={styles.buttons}>
        <ExchangeButtons />
        <SendButton />
      </div>
    </div>
  )
}
