import React from 'react'

import Typography from 'shared/components/Typography'

import DocumentPage from '../../components/DocumentPage/DocumentPage'

import styles from './Contacts.module.css'

const Contacts: React.FC = () => {
  return (
    <DocumentPage className={styles.root}>
      <Typography size="l" weight="bold" className={styles.subtitle}>
        Реквизиты юридического лица
      </Typography>
      <Typography weight="light" size="m" className={styles.paragraph}>
        Юридическое лицо:
      </Typography>
      <Typography weight="light" size="m" className={styles.paragraph}>
        ИП Дьяченко Антон Валентинович
      </Typography>
      <Typography weight="light" size="m" className={styles.paragraph}>
        ИНН 290121729739
      </Typography>
      <Typography weight="light" size="m" className={styles.paragraph}>
        ОГРН 31729010000877
      </Typography>
      <Typography weight="light" size="m" className={styles.paragraph}>
        Юридический адрес: 108814, Россия, г. Москва, пр-кт Магелана, д. 2, кв. 152
      </Typography>
    </DocumentPage>
  )
}

export default Contacts
