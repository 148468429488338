import React from 'react'

import Typography from 'shared/components/Typography'

import styles from './InstructionTabs.module.css'

type Props = {
  title: string
  description: string
  info?: string
}

export const InstructionTab: React.FC<Props> = ({ title, description, info }) => {
  return (
    <div className={styles.tabContent}>
      <Typography color="white" size="xxl" weight="bold" className={styles.title}>
        {title}
      </Typography>
      <Typography color="lightGray" size="l" weight="light" spacy className={styles.description}>
        {description}
      </Typography>
      {info && (
        <Typography color="white" weight="bold" className={styles.info}>
          {info}
        </Typography>
      )}
    </div>
  )
}
