import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import { Divider } from 'shared/components/Divider'
import { Heading } from 'shared/components/Heading'
import { Spinner } from 'shared/components/Spinner'
import Typography from 'shared/components/Typography'
import { reviews as reviewsRoute } from 'shared/constants/routes'
import { BaseLayout } from 'shared/core/layouts/BaseLayout'

import { ReviewsList } from './components/ReviewsList'
import { DEFAULT_GET_PARAMS_REVIEWS } from './constants'
import { actions } from './ducks'
import { getReviews, getReviewsFilter, getReviewsIsLoading } from './ducks/selectors'
import styles from './Reviews.module.css'

const Reviews = () => {
  const dispatch = useDispatch()
  const reviews = useSelector(getReviews)
  const isLoading = useSelector(getReviewsIsLoading)
  const filter = useSelector(getReviewsFilter)

  useEffect(() => {
    dispatch(actions.fetchReviews({ ...DEFAULT_GET_PARAMS_REVIEWS, ...filter }))
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>{reviewsRoute.title}</title>
        <meta property="yandex_recommendations_title" content={reviewsRoute.title} />
        <meta
          property="yandex_recommendations_image"
          content="https://www.ea.com/fifa/ultimate-team/web-app/content/21D4F1AC-91A3-458D-A64E-895AA6D871D1/2021/fut/sbc/companion/sets/images/sbc_set_image_10002350-6c7925ef-a043.png"
        />
      </Helmet>

      <BaseLayout>
        <main className={styles.main}>
          <Divider className={styles.divider} />
          <Heading component="h1">Отзывы</Heading>
          <Typography className={styles.subTitle} weight="light" color="secondary" size="l" spacy>
            Оставлять отзыв можно после совершения покупки
          </Typography>
          {!isLoading && !!reviews.length && <ReviewsList reviews={reviews} className={styles.list} />}
          {isLoading && <Spinner />}
        </main>
      </BaseLayout>
    </>
  )
}

export default Reviews
