import { nanoid } from 'nanoid'
import React, { forwardRef, useRef, useState } from 'react'
import cn from 'clsx'

import useCombinedRefs from 'shared/hooks/useCombinedRefs'

import { SwitchProps } from './types'
import styles from './Switch.module.css'

export const Switch: React.FC<SwitchProps> = forwardRef<HTMLInputElement, SwitchProps>(function SwitchBase(props, ref) {
  const { checked = false, id, name, onChange, disabled = false, className, ...rest } = props

  const [isChecked, setIsChecked] = useState<boolean>(() => checked)

  const innerRef = useRef<HTMLInputElement>(null)
  const combinedRef = useCombinedRefs<HTMLInputElement>(ref, innerRef)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (disabled) return

    setIsChecked(event.target.checked)

    if (onChange) {
      onChange(event.target.checked, event)
    }
  }

  const elementId = id || nanoid()

  const switchCn = cn(styles.switch, className, {
    [styles.switch_checked]: isChecked,
  })

  return (
    <label htmlFor={elementId} className={switchCn}>
      <input
        id={elementId}
        className={styles.input}
        type="checkbox"
        role="switch"
        checked={isChecked}
        name={name}
        onChange={handleChange}
        ref={combinedRef}
        {...rest}
      />
    </label>
  )
})
