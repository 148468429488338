import clsx from 'clsx'
import React from 'react'

import Dialog, { DialogProps, DialogSize } from 'shared/components/Dialog'

import styles from './Modal.module.css'

export const Modal: React.FC<DialogProps> = ({ children, size = DialogSize.small, className, ...rest }) => (
  <Dialog open withBackdrop={false} size={size} classNameModal={clsx(styles.modal, className)} {...rest}>
    <div className={styles.body}>{children}</div>
  </Dialog>
)
