import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIsOpenConnection } from 'shared/features/WebSocket/ducks/selectors'
import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { send } from 'shared/features/WebSocket/WebSocketService'
import consoleError from 'shared/utils/consoleError'

import { BuyStatus, StepName } from '../constants'
import { actions } from '../ducks'
import { getBuyStatus, getOrderInitialBalance } from '../ducks/selectors'
import { BuyInfo } from '../types'

enum TooltipMessage {
  сonnection = 'Подключение к серверу...',
  orderSending = 'Подождите минуту, идет отправка сообщения',
  beginSending = 'Это займет не больше одной минуты, после чего сайт подскажет дальнейшие действия',
}

type BeginHook = {
  tooltip: string
  isSendingMessage: boolean
  isOpenConnection: boolean
  sendBeginMessage: (initialBalance: number) => void
}

export default (): BeginHook => {
  const dispatch = useDispatch()

  const buyStatus = useSelector(getBuyStatus)
  const initialBalance = useSelector(getOrderInitialBalance)
  const isOpenConnection = useSelector(getIsOpenConnection)

  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false)
  const [tooltip, setTooltip] = useState<string>(TooltipMessage.сonnection)

  const sendBeginMessage = async (initialBalance: number) => {
    setTooltip(TooltipMessage.beginSending)
    setIsSendingMessage(true)
    try {
      await send(WebSocketMessageService.getBeginMessage(initialBalance))
    } catch (error) {
      setIsSendingMessage(false)
      setTooltip('')
    }
  }

  const sendOrderMessage = async () => {
    setTooltip(TooltipMessage.orderSending)
    setIsSendingMessage(true)
    try {
      const data = await send<BuyInfo>(WebSocketMessageService.getOrderMessage())
      dispatch(actions.setBuyInfo(data))
      await sendBeginMessage(initialBalance)
    } catch (error) {
      consoleError(error)
    } finally {
      setIsSendingMessage(false)
      setTooltip('')
    }
  }

  useEffect(() => {
    if (isOpenConnection && !isSendingMessage) {
      sendOrderMessage()
    }
  }, [isOpenConnection])

  useEffect(() => {
    if (buyStatus === BuyStatus.buying) {
      setIsSendingMessage(false)
      dispatch(actions.setActiveStep(StepName.Step1))
    }

    if (buyStatus === BuyStatus.selling) {
      dispatch(actions.setIsStartSelling(true))
      dispatch(actions.setActiveStep(StepName.Step3))
    }

    if (buyStatus === BuyStatus.complete) {
      dispatch(actions.setActiveStep(StepName.Final))
    }
  }, [buyStatus])

  return {
    tooltip,
    isSendingMessage,
    isOpenConnection,
    sendBeginMessage,
  }
}
