import React from 'react'

import Button from 'shared/components/Button'
import { PlayerCard } from 'shared/components/PlayerCard'
import Typography from 'shared/components/Typography'
import { RequiredPlayersCount, Trade } from 'shared/features/Buy/types'
import mapTradeToPlayerCardProps from 'shared/features/Buy/utils/mapTradeToPlayerCardProps'

import { Modal } from '../Modal'

import styles from './PlayersConfirmModal.module.css'

type Props = {
  auctions: Trade[]
  confirm: () => void
  decline: () => void
  requiredPlayersCount: RequiredPlayersCount
}

export const PlayersConfirmModal: React.FC<Props> = ({ auctions, confirm, decline, requiredPlayersCount }) => {
  const isOnePlayer = requiredPlayersCount === 1
  const title = isOnePlayer ? 'найденный игрок' : 'найденные игроки'
  const subTitle = isOnePlayer ? 'Это ваш игрок?' : 'Это ваши игроки?'

  const cards = auctions.map(auction => {
    const { id, ...cardProps } = mapTradeToPlayerCardProps(auction)
    return <PlayerCard {...cardProps} id={id} key={id} />
  })

  return (
    <Modal title={title}>
      <Typography>{subTitle}</Typography>
      <div className={styles.cards}>{cards}</div>
      <div className={styles.buttons}>
        <Button outline onClick={decline} color="darkGray">
          Нет
        </Button>
        <Button onClick={confirm}>Да</Button>
      </div>
    </Modal>
  )
}
