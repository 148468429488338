import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getActiveStep } from '../../ducks/selectors'
import { StepName } from '../../constants'

import styles from './Steps.module.css'
import { Payment } from './Payment'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Final } from './Final'

export const Steps = () => {
  const name = useSelector(getActiveStep)
  const stepComponent = useMemo((): React.ReactElement => {
    const stepComponents: Record<StepName, React.ReactElement> = {
      [StepName.Payment]: <Payment />,
      [StepName.Step1]: <Step1 />,
      [StepName.Step2]: <Step2 />,
      [StepName.Step3]: <Step3 />,
      [StepName.Final]: <Final />,
    }

    return stepComponents[name]
  }, [name])

  return <div className={styles.steps}>{stepComponent}</div>
}
