import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FutCoin from 'shared/components/FutCoin'
import formatNumber from 'shared/utils/formatNumber'
import {
  getAmountNeeded,
  getIsPaymentError,
  getIsOrderPaid,
  getPaymentErrorMessage,
} from 'shared/features/Buy/ducks/selectors'
import { StepTitle } from 'shared/features/Buy/constants'
import { actions as profileActions } from 'shared/features/Profile/ducks'
import { InfoCard } from 'shared/components/InfoCard'
import useBegin from 'shared/features/Buy/hooks/useBegin'
import { actions as notifyActions } from 'shared/features/Notification/ducks'
import { getErrorNotice } from 'shared/features/Notification/utils'

import { Step } from '../Step'

import styles from './Payment.module.css'
import { PaymentDescription } from './PaymentDescription'
import { SendForm } from './SendForm'
import { SendMessagesLoading } from './SendMessagesLoading'

export const Payment = () => {
  const { isSendingMessage, tooltip } = useBegin()
  const dispatch = useDispatch()

  const amountNeeded = useSelector(getAmountNeeded)
  const isOrderPaid = useSelector(getIsOrderPaid)
  const isPaymentError = useSelector(getIsPaymentError)
  const errorMessage = useSelector(getPaymentErrorMessage)

  useEffect(() => {
    if (isOrderPaid) {
      dispatch(profileActions.getAccount())
    }
  }, [isOrderPaid])

  useEffect(() => {
    if (isPaymentError) {
      dispatch(notifyActions.errorNotify(getErrorNotice(errorMessage)))
    }
  }, [isPaymentError, errorMessage])

  return (
    <Step title={StepTitle.Payment}>
      {isSendingMessage && <SendMessagesLoading text={tooltip} />}
      {!isSendingMessage && (
        <>
          <InfoCard
            label="Вы приобретаете:"
            value={formatNumber(amountNeeded)}
            icon={<FutCoin className={styles.icon} />}
            className={styles.card}
          />
          <SendForm />
          <PaymentDescription />
        </>
      )}
    </Step>
  )
}
