import { Formik, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Input from 'shared/components/Input'
import { getLots, getRequiredPlayer, getRequiredPlayersCount } from 'shared/features/Buy/ducks/selectors'
import useStep3 from 'shared/features/Buy/hooks/useStep3'
import { Player } from 'shared/features/Buy/types'
import useDebounce from 'shared/hooks/useDebounce'
import Button from 'shared/components/Button'

import { PlayersConfirmModal } from '../../Modals/PlayersConfirmModal/PlayersConfirmModal'
import { PlayersNotFoundModal } from '../../Modals/PlayersNotFoundModal'
import { SellingLoading } from '../../SellingLoading'
import { SelectTradeModal } from '../../Modals/SelectTradeModal'

import styles from './Step3.module.css'
import { PlayersSearchList } from './PlayersSearchList'
import { SearchInfo } from './SearchInfo'
import { SearchLoading } from './SearchLoading'

export type FormPayload = {
  search: string
}

export const SendForm = () => {
  const requiredPlayersCount = useSelector(getRequiredPlayersCount)
  const requiredPlayer = useSelector(getRequiredPlayer)
  const lots = useSelector(getLots)

  const {
    players,
    auctions,
    selectTrades,
    sendSearchAuctions,
    sendSearchPlayer,
    sendSelectTrade,
    sendNextStep,
    reset,
    clearLots,
    onSubmit,
    currentSearchAuctionsPlayer,
    isStartSearchPlayers,
    isShowPlayersConfirmModal,
    isShowPlayersNotFoundModal,
    isShowSearchLoading,
    isShowSellingLoading,
    isShowSelectTradeModal,
  } = useStep3(requiredPlayersCount, requiredPlayer)

  const initialValues: FormPayload = {
    search: '',
  }

  // TODO убрать форму, т.к. отсутствует onSubmit button
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values: { search } }: FormikProps<FormPayload>) => {
        const searchQuery = useDebounce<string>(search, 300)

        useEffect(() => {
          sendSearchPlayer(searchQuery)
        }, [searchQuery])

        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            {!requiredPlayer && (
              <div className={styles.input_wrapper}>
                <Input
                  name="search"
                  value={search}
                  onChange={handleChange}
                  placeholder="Поиск"
                  fullWidth
                  loading={isStartSearchPlayers}
                />
                <PlayersSearchList players={players} onClick={sendSearchAuctions} />
              </div>
            )}
            {!!requiredPlayer && (
              <Button
                onClick={() => {
                  sendSearchAuctions(requiredPlayer)
                }}
                uppercase
              >
                Повторить поиск
              </Button>
            )}
            <SearchInfo
              auctions={auctions}
              currentPlayersCount={auctions.length}
              requiredPlayersCount={requiredPlayersCount}
            />
            {isShowSearchLoading && (
              <SearchLoading
                player={currentSearchAuctionsPlayer as Player}
                requiredPlayersCount={requiredPlayersCount}
                currentPlayersCount={auctions.length}
              />
            )}
            {isShowSellingLoading && <SellingLoading lots={lots} />}
            {isShowPlayersNotFoundModal && (
              <PlayersNotFoundModal
                retry={() => {
                  sendSearchAuctions(currentSearchAuctionsPlayer as Player)
                }}
                decline={reset}
              />
            )}
            {isShowPlayersConfirmModal && (
              <PlayersConfirmModal
                auctions={auctions}
                confirm={sendNextStep}
                decline={clearLots}
                requiredPlayersCount={requiredPlayersCount}
              />
            )}
            {isShowSelectTradeModal && <SelectTradeModal onSelect={sendSelectTrade} trades={selectTrades} />}
          </form>
        )
      }}
    </Formik>
  )
}
