import React from 'react'
import { useSelector } from 'react-redux'

import FutCoin from 'shared/components/FutCoin'
import { ReactComponent as TimeIcon } from 'shared/assets/icons/time.svg'
import formatNumber from 'shared/utils/formatNumber'
import { getBuyNowPrice, getStartingBid } from 'shared/features/Buy/ducks/selectors'
import { StepTitle } from 'shared/features/Buy/constants'
import { InfoCardProps } from 'shared/components/InfoCard'

import { NextStepButton } from '../../NextStepButton'
import { Step } from '../Step'
import { InfoCards } from '../../InfoCards/InfoCards'

import styles from './Step2.module.css'

export const Step2 = () => {
  const buyNowPrice = useSelector(getBuyNowPrice)
  const startingBid = useSelector(getStartingBid)

  const cards: InfoCardProps[] = [
    {
      label: 'Начальная цена:',
      value: formatNumber(startingBid),
      icon: <FutCoin className={styles.icon} />,
    },
    {
      label: 'Цена «купить сейчас»:',
      value: formatNumber(buyNowPrice),
      icon: <FutCoin className={styles.icon} />,
    },
    {
      label: 'Трансферное время:',
      value: '6 часов',
      icon: <TimeIcon className={styles.icon} />,
    },
  ]

  return (
    <Step title={StepTitle.Step2}>
      <InfoCards cards={cards} className={styles.cards} />
      <NextStepButton className={styles.button} />
    </Step>
  )
}
