import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { StepName } from 'shared/features/Buy/constants'
import { actions as buyActions } from 'shared/features/Buy/ducks'
import { buy as buyRoute } from 'shared/constants/routes'
import {
  getAmount,
  getBuyPlatform,
  getInitialBalance,
  getIsErrorAmount,
  getIsErrorInitialBalance,
  getLastOrder,
  getLastOrderError,
} from 'shared/features/Buy/ducks/selectors'
import { getAuthorized } from 'shared/features/Profile/ducks/selectors'

type StartHook = {
  isLoading: boolean
  isDisabled: boolean
  tooltipText: string
  startHandler: () => void
}

export default (): StartHook => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [intervalId, setIntervalId] = useState<NodeJS.Timer>()
  const [createOrderIsLoading, setCreateOrderIsLoading] = useState(false)

  const isAuthorized: boolean = useSelector(getAuthorized)
  const lastOrder = useSelector(getLastOrder)
  const lastOrderError = useSelector(getLastOrderError)
  const platform = useSelector(getBuyPlatform)
  const amount = useSelector(getAmount)
  const eaBalance = useSelector(getInitialBalance)
  const isInitBalanceError = useSelector(getIsErrorInitialBalance)
  const isAmountError = useSelector(getIsErrorAmount)

  const tooltipText = useMemo(() => {
    if (!isAuthorized) {
      return 'Авторизуйтесь для продолжения'
    }

    if (lastOrderError) {
      return 'Внутренняя ошибка сервера'
    }

    if (isAmountError) {
      return `Минимальное количество ${MIN_COINS_AMOUNT} монет`
    }

    return ''
  }, [isAuthorized, isAmountError, lastOrderError])

  const startHandler = () => {
    setCreateOrderIsLoading(true)
    dispatch(
      buyActions.createOrder({
        amount,
        platform,
        eaBalance,
      }),
    )

    const id = setInterval(() => {
      dispatch(buyActions.getLastOrder())
    }, 1000 * 10)

    setIntervalId(id)
  }

  useEffect(() => {
    if (lastOrder) {
      clearInterval(intervalId)
      dispatch(buyActions.setActiveStep(StepName.Payment))
      setCreateOrderIsLoading(false)
      history.push(buyRoute.path)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [lastOrder])

  return {
    isLoading: createOrderIsLoading,
    isDisabled: !isAuthorized || createOrderIsLoading || isInitBalanceError || isAmountError || lastOrderError,
    tooltipText,
    startHandler,
  }
}
