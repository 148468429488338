import React from 'react'

import { useWindowSize } from 'shared/hooks/useWindowSize'

import { BonusInfo } from '../BonusInfo'
import { BuyBlock } from '../BuyBlock'
import { HowTo } from '../HowTo'

import styles from './Main.module.css'

export const Main: React.FC = () => {
  const { isMobile } = useWindowSize()
  return (
    <div className={styles.root}>
      <BuyBlock />
      {!isMobile && <BonusInfo />}
      <HowTo />
      <img className={styles.img} src="/images/bg_pattern_blue.png" alt="bg_pattern_blue" />
    </div>
  )
}
