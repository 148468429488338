import React from 'react'

import { ReactComponent as SuccessIcon } from 'shared/assets/icons/success.svg'
import Button from 'shared/components/Button'
import Typography from 'shared/components/Typography'

import { Modal } from '../Modal'

import styles from './ReviewSuccessModal.module.css'

type Props = {
  confirm: () => void
}

export const ReviewSuccessModal: React.FC<Props> = ({ confirm }) => (
  <Modal>
    <SuccessIcon className={styles.icon} />
    <Typography size="m" color="secondary" weight="light" className={styles.description}>
      Благодарим вас за оставленный отзыв! Вы делаете наш сайт лучше
    </Typography>
    <Button fullWidth className={styles.button} onClick={confirm}>
      Продолжить
    </Button>
  </Modal>
)
