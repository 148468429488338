import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Pagination, { PaginationProps } from 'shared/components/Pagination'

import { actions } from '../../ducks'
import { getReviewsFilter, getReviewsPagination } from '../../ducks/selectors'

export const ReviewsPagination: React.FC<Partial<PaginationProps>> = ({ className }) => {
  const dispatch = useDispatch()

  const pagination = useSelector(getReviewsPagination)
  const filter = useSelector(getReviewsFilter)

  const paginationHandle = (page: number, size: number) => {
    dispatch(
      actions.fetchReviews({
        page,
        size,
        ...filter,
      }),
    )
  }

  return <Pagination onChange={paginationHandle} className={className} {...pagination} />
}
