import React from 'react'

import { useWindowSize } from 'shared/hooks/useWindowSize'

import Typography, { TypographyProps, TypographySize } from '../Typography'

export const Heading: React.FC<TypographyProps> = props => {
  const { isMobile } = useWindowSize()

  const size: TypographySize = isMobile ? TypographySize.xxl : TypographySize.xxxxl

  return <Typography weight="bold" component="h2" size={size} uppercase {...props} />
}
