import React from 'react'
import cn from 'clsx'

import { Review } from '../../types'
import { ReviewItem } from '../ReviewItem'
import { ReviewsPagination } from '../ReviewsPagination'

import styles from './ReviewsList.module.css'

type Props = {
  reviews: Review[]
  className?: string
}

export const ReviewsList: React.FC<Props> = ({ reviews, className }) => {
  const list = reviews.map(review => <ReviewItem key={review.id} className={styles.review} {...review} />)

  return (
    <div className={cn(className, styles.root)}>
      <div className={styles.list}>{list}</div>
      <div className={styles.bgImg} />
      <ReviewsPagination className={styles.pagination} />
    </div>
  )
}
