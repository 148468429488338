import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIsOpenConnection } from 'shared/features/WebSocket/ducks/selectors'
import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { closeConnection, send } from 'shared/features/WebSocket/WebSocketService'

import { BuyStatus } from '../constants'
import { actions as buyActions } from '../ducks'
import { getBuyStatus, getCancelOrderIsLoading, getOrderUuid } from '../ducks/selectors'

type StopSessionHook = {
  stopSession: () => void
  isLoading: boolean
}

export default (): StopSessionHook => {
  const dispatch = useDispatch()

  const [isPrepareStopMessage, setIsPrepareStopMessage] = useState<boolean>(false)

  const buyStatus = useSelector(getBuyStatus)
  const isOpenConnection = useSelector(getIsOpenConnection)
  const orderUUID = useSelector(getOrderUuid)
  const cancelOrderIsLoading = useSelector(getCancelOrderIsLoading)

  const cancelOrder = () => {
    dispatch(
      buyActions.cancelOrder({
        orderUUID,
      }),
    )
  }

  const stopSession = useCallback(() => {
    if (isOpenConnection) {
      setIsPrepareStopMessage(true)
      send(WebSocketMessageService.getCancelMessage())
      return
    }

    cancelOrder()
  }, [orderUUID, isOpenConnection])

  useEffect(() => {
    if (isPrepareStopMessage && (buyStatus === BuyStatus.cancelled || buyStatus === BuyStatus.complete)) {
      setIsPrepareStopMessage(false)
      closeConnection()
      dispatch(buyActions.reset())
    }
  }, [buyStatus])

  return {
    stopSession,
    isLoading: cancelOrderIsLoading || isPrepareStopMessage,
  }
}
