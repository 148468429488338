import React, { PureComponent } from 'react'

type TState = {
  error: boolean
}

export default class ErrorBoundary extends PureComponent<unknown, TState> {
  readonly state: TState = {
    error: false,
  }

  componentDidCatch(): void {
    this.setState({
      error: true,
    })
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return <div>Произошла ошибка</div>
    }

    return children
  }
}
