import React, { useEffect } from 'react'
import cn from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import { InputSlider } from 'shared/components/InputSlider'
import Typography, { TypographySize } from 'shared/components/Typography'
import formatNumber from 'shared/utils/formatNumber'
import { getAmount, getMaxCoinsAmount, getPrice } from 'shared/features/Buy/ducks/selectors'
import { ReactComponent as RblIcon } from 'shared/assets/icons/currency_rbl.svg'
import { actions as buyActions } from 'shared/features/Buy/ducks'
import InputNumber from 'shared/components/InputNumber'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import { InitialBalance } from '../InitialBalance'

import styles from './BuyForm.module.css'

const STEP = 10

export const InputInfo = () => {
  const dispatch = useDispatch()

  const { isMobile } = useWindowSize()

  const maxCoinsAmount = useSelector(getMaxCoinsAmount)
  const price = useSelector(getPrice)
  const amount = useSelector(getAmount)

  const labelSize: TypographySize = isMobile ? TypographySize.xs : TypographySize.s
  const valueSize: TypographySize = isMobile ? TypographySize.l : TypographySize.xxxl

  const onChangeSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(buyActions.setAmount(Number.parseInt(e.target.value, 10)))
  }

  const onChange = (value: number) => {
    const amount = value > maxCoinsAmount ? maxCoinsAmount : value

    dispatch(buyActions.setAmount(amount))
  }

  const onBlur = (value: number) => {
    const amountCeil = value % STEP === 0 ? value : Math.ceil(value / STEP) * STEP
    const amount = amountCeil < MIN_COINS_AMOUNT ? MIN_COINS_AMOUNT : amountCeil
    onChange(amount)
  }

  useEffect(() => {
    if (amount <= maxCoinsAmount) {
      return
    }

    dispatch(buyActions.setAmount(maxCoinsAmount))
  }, [maxCoinsAmount])

  return (
    <>
      <InitialBalance />
      <div className={styles.values}>
        <div className={cn(styles.values__item, styles.amount)}>
          <img src="/images/futcoin.png" alt="futcoin" className={styles.values__item_icon} />
          <div className={styles.values__item_label_value}>
            <Typography color="secondary" className={styles.amount__label} size={labelSize}>
              Количество
            </Typography>
            <InputNumber
              size="s"
              className={styles.amount__input}
              onChange={onChange}
              onBlur={onBlur}
              value={amount}
              max={maxCoinsAmount}
              fullWidth
              withoutBorder
            />
          </div>
        </div>
        <div className={cn(styles.values__item, styles.cost)}>
          <RblIcon className={styles.values__item_icon} />
          <div className={styles.values__item_label_value}>
            <Typography color="secondary" className={styles.cost__label} size={labelSize}>
              Стоимость, руб
            </Typography>
            <Typography size={valueSize} weight="bold" className={styles.cost__price}>
              {formatNumber(price)}
            </Typography>
          </div>
        </div>
      </div>
      <InputSlider min={MIN_COINS_AMOUNT} max={maxCoinsAmount} step={STEP} onChange={onChangeSlider} value={amount} />
    </>
  )
}
