import React, { useEffect } from 'react'
import cn from 'clsx'
import { useDispatch } from 'react-redux'

import { Spinner } from 'shared/components/Spinner'
import { actions as buyActions } from 'shared/features/Buy/ducks'

import styles from './Preloader.module.css'

type Props = React.HTMLAttributes<HTMLDivElement>

export const Preloader: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(buyActions.preloadInfo())
  }, [])

  return (
    <div className={cn(className, styles.root)}>
      <Spinner />
    </div>
  )
}
