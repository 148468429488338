import React from 'react'

import Typography from 'shared/components/Typography'

import { Tabs } from '../Tabs'

import styles from './Steps.module.css'

type Props = {
  title: string
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

export const Step: React.FC<Props> = ({ children, title, icon: Icon }) => (
  <div className={styles.step}>
    <Tabs />
    {Icon && <Icon className={styles.icon} />}
    <Typography size="xxxl" weight="bold" uppercase className={styles.title}>
      {title}
    </Typography>
    {children}
  </div>
)
