import React from 'react'
import { Helmet } from 'react-helmet-async'
import cn from 'clsx'

import { Divider } from 'shared/components/Divider'
import { Heading } from 'shared/components/Heading'
import { BaseLayout } from 'shared/core/layouts/BaseLayout'

import styles from './DocumentPage.module.css'

interface DocumentPageProps {
  title?: string
  className?: string
}

const DocumentPage: React.FC<DocumentPageProps> = ({ title, className, children }) => {
  const heading = title && <Heading className={styles.heading}>{title}</Heading>

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseLayout>
        <div className={cn(styles.root, className)}>
          <Divider className={styles.devider} />
          {heading}
          <div className={styles.info}>{children}</div>
          <div className={styles.bgImg} />
        </div>
      </BaseLayout>
    </>
  )
}

export default DocumentPage
