import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Container from 'shared/core/layouts/Container'
import { buy as buyRoute, home as homeRoute } from 'shared/constants/routes'
import { BaseLayout } from 'shared/core/layouts/BaseLayout'

import { actions as profileActions } from '../Profile/ducks'

import { actions as buyActions } from './ducks'
import { HeadInfo } from './components/HeadInfo'
import styles from './Buy.module.css'
import { Steps } from './components/Steps'
import { getIsOrderCanceled, getLastOrder } from './ducks/selectors'
import { OrderCanceledModal } from './components/Modals/OrderCanceledModal'

const Buy = () => {
  const dispatch = useDispatch()
  const lastOrder = useSelector(getLastOrder)
  const isOrderCanceled = useSelector(getIsOrderCanceled)

  const history = useHistory()

  useEffect(() => {
    if (!lastOrder) {
      history.push(homeRoute.path)
    }
  }, [lastOrder])

  useEffect(
    () => () => {
      dispatch(profileActions.getAccount())
      dispatch(buyActions.reset())
    },
    [],
  )

  return (
    <>
      <Helmet>
        <title>{buyRoute.title}</title>
        <meta property="yandex_recommendations_title" content={buyRoute.title} />
        <meta
          property="yandex_recommendations_image"
          content="https://www.ea.com/fifa/ultimate-team/web-app/content/21D4F1AC-91A3-458D-A64E-895AA6D871D1/2021/fut/sbc/companion/sets/images/sbc_set_image_10002350-6c7925ef-a043.png"
        />
      </Helmet>

      <BaseLayout>
        <Container className={styles.main} element="main">
          {!!lastOrder && !isOrderCanceled && (
            <>
              <HeadInfo />
              <Steps />
            </>
          )}
          {isOrderCanceled && (
            <OrderCanceledModal
              confirm={() => {
                dispatch(buyActions.reset())
              }}
            />
          )}
        </Container>
      </BaseLayout>
    </>
  )
}

export default Buy
