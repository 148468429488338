import { useDispatch, useSelector } from 'react-redux'
import React, { forwardRef, useRef } from 'react'

import Button, { ButtonProps } from 'shared/components/Button'
import useCombinedRefs from 'shared/hooks/useCombinedRefs'

import { actions } from '../../ducks'
import { getActiveStep } from '../../ducks/selectors'
import { getNextStepByName } from '../../utils/getNextStepByName'

type NextStepButtonProps = ButtonProps & {
  preventRedirect?: boolean
}

export const NextStepButton = forwardRef<HTMLButtonElement, NextStepButtonProps>(function NextStepButtonBase(
  props,
  ref,
) {
  const { preventRedirect = false, onClick, ...rest } = props

  const dispatch = useDispatch()

  const activeStep = useSelector(getActiveStep)

  const innerRef = useRef<HTMLButtonElement>(null)
  const combinedRef = useCombinedRefs<HTMLButtonElement>(ref, innerRef)

  const clickHandle: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (onClick) {
      onClick(e)
    }
    if (!preventRedirect) {
      dispatch(actions.setActiveStep(getNextStepByName(activeStep)))
    }
  }

  return (
    <Button ref={combinedRef} onClick={clickHandle} {...rest}>
      Продолжить
    </Button>
  )
})
