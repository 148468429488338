import { WebSocketMessageService } from 'shared/features/WebSocket/WebSocketMessageService'
import { send } from 'shared/features/WebSocket/WebSocketService'
import consoleError from 'shared/utils/consoleError'

type ClearLotsHook = {
  sendClearLots: () => void
}

export default (): ClearLotsHook => {
  const sendClearLots = async () => {
    try {
      await send<boolean>(WebSocketMessageService.getClearLotsMessage())
    } catch (error) {
      consoleError(error)
    }
  }

  return {
    sendClearLots,
  }
}
