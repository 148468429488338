import React from 'react'
import { useSelector } from 'react-redux'

import FutCoin from 'shared/components/FutCoin'
import formatNumber from 'shared/utils/formatNumber'
import { ReactComponent as ShieldIcon } from 'shared/assets/icons/shield.svg'
import { getRecommendedBuyPrice, getRequiredPlayersCount } from 'shared/features/Buy/ducks/selectors'
import { InfoCardProps } from 'shared/components/InfoCard'

import { NextStepButton } from '../../NextStepButton'
import { InfoCards } from '../../InfoCards/InfoCards'

import styles from './Step1.module.css'

export const NoRequiredPlayer = () => {
  const recommendedBuyPrice = useSelector(getRecommendedBuyPrice)
  const requiredPlayersCount = useSelector(getRequiredPlayersCount)

  const playersCount = requiredPlayersCount === 1 ? `${requiredPlayersCount} игрок` : `${requiredPlayersCount} игрока`

  const cards: InfoCardProps[] = [
    {
      label: 'Количество:',
      value: playersCount,
      icon: <ShieldIcon className={styles.icon} />,
    },
    {
      label: 'Не дороже:',
      value: `${formatNumber(recommendedBuyPrice)} монет`,
      icon: <FutCoin className={styles.icon} />,
    },
  ]

  return (
    <>
      <InfoCards cards={cards} className={styles.cards} />
      <div className={styles.actions}>
        <div className={styles.action}>
          <NextStepButton fullWidth />
        </div>
      </div>
    </>
  )
}
