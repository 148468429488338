export const termBlocks = [
  {
    subtitle: '1. Принятие пользовательского соглашения и поправок',
    paragraphs: [
      'Каждый раз, когда вы используете или вызываете доступ к этому веб-сайту (www.futtycoin.ru), вы соглашаетесь соблюдать это Пользовательское соглашение, с поправками, которые время от времени изменяются с уведомлением или без него. Кроме того, если вы используете конкретную услугу на этом веб-сайте или через этот веб-сайт, вы будете соблюдать какие-либо правила или рекомендации, применимые к этим услугам, и они должны быть включены посредством ссылки в настоящее Пользовательское соглашение. см. Нашу Политику конфиденциальности.',
    ],
  },
  {
    subtitle: '2. Наше обслуживание',
    paragraphs: [
      'Наш веб-сайт и услуги, предоставляемые вам на нашем веб-сайте и через наш веб-сайт, предоставляются на основе «как есть». Вы соглашаетесь с тем, что владельцы этого веб-сайта оставляют за собой исключительное право и могут в любое время и без уведомления и любой ответственности перед вами изменить или прекратить действие этого веб-сайта и его услуг или удалить предоставленные вами данные, будь то временно или постоянно. Мы не несем никакой ответственности за своевременность, исключение, несоблюдение, неточность или ненадлежащее предоставление каких-либо данных или информации. Хотя поставки (для цифровых товаров) обычно доставляются почти мгновенно, иногда они могут занимать до 3 рабочих дней. Мы не аффилированы компанией Electronic Arts и ее дочерними предприятиями. Все права на аудио, фото, изображения и видео материалы, представленные на нашем сайте принадлежат их законным владельцам и предназначены только для ознакомления. Все материалы, размещенные на сайте, взяты с открытых (общедоступных) источников.',
    ],
  },
  {
    subtitle: '3. Ваши обязанности при регистрации',
    paragraphs: [
      'Чтобы использовать этот веб-сайт, вам необходимо или может быть предложено зарегистрироваться на нашем сайте и согласиться предоставить правдивую информацию по запросу. При регистрации вы в явной форме соглашаетесь с нашим Пользовательским соглашением. Любые изменения пользовательского соглашения вы можете найти здесь.',
    ],
  },
  {
    subtitle: '4. Политика конфиденциальности',
    paragraphs: [
      'Регистрационные данные и другая личная информация, которую мы можем собирать, подчиняются условиям нашей Политики конфиденциальности.',
    ],
  },
  {
    subtitle: '5. Регистрация и пароль',
    paragraphs: [
      'Вы несете ответственность за сохранение конфиденциальности своего пароля и будете нести ответственность за все виды использования посредством вашей регистрации и / или авторизации, независимо от того, пользовались вашим аккаунтом вы или третье лицо. Вы соглашаетесь немедленно уведомить нас о любом несанкционированном использовании вашей учетной записи пользователя или пароле.',
    ],
  },
  {
    subtitle: '6. Ваше поведение',
    paragraphs: [
      'Вы соглашаетесь с тем, что наш веб-сайт может предоставить вам контент, который может быть неприемлемым или оскорбительным. Мы не будем нести ответственность за содержание, которое появляется на этом веб-сайте, а также за любые ошибки или упущения. Вы прямо соглашаетесь, используя этот веб-сайт или любую предоставленную услугу, что вы не будете:',
      '(А) предоставлять любой Контент или выполнять любые действия, которые могут быть незаконными, угрожающими, вредоносными, оскорбительными, назойливыми, дискредитирующими, клеветническими, вульгарными, непристойными, неприемлемыми, порнографическими или мешающими пребыванию на этом веб-сайте. Предоставление любой услуги, зараженной вирусом или другой деструктивной или вредной программы, порождает гражданскую или уголовную ответственность или нарушающую местное, национальное или международное право;',
      '(Б) олицетворять или искажать вашу связь с любым лицом или юридическим лицом или подделывать или иным образом пытаться скрыть или исказить источник любого Содержимого, предоставленного вами;',
      '(В) собирать любые данные о других пользователях;',
      '(Г) предоставлять или использовать этот веб-сайт и любой контент или услугу любым коммерческим способом или любым способом, который включал бы нежелательную почту, спам, цепочку писем, схемы пирамиды или любую другую форму несанкционированной рекламы без нашего предварительного письменного согласия;',
      '(Д) предоставлять любой Контент, который может привести к нашей гражданской или уголовной ответственности или который может быть признан или считаться нарушением какого-либо местного, национального или международного права, включая, но не ограничиваясь, законы, касающиеся авторского права, товарного знака, патента или торговли секретами.',
      '(Е) представителем, сотрудником, любым другим лицом, аффилированных компанией Electronic Arts ее дочерними предприятиями.',
    ],
  },
  {
    subtitle: '7. Услуги третьих лиц',
    paragraphs: [
      'Товары и услуги третьих лиц могут рекламироваться и / или предоставляться на этом веб-сайте или через него (www.futtycoin.ru). Все продукты и услуги предоставляемые третьими лицами регулируются политикой данного сайта. Мы не несем никакой ответственности за любые ваши действия или взаимодействие с третьими лицами.',
    ],
  },
  {
    subtitle: '8. Компенсация',
    paragraphs: [
      'В случае отмены заказа на начальном этапе покупки:',
      '1. Если заказ был оплачен исключительно в денежном эквиваленте, данные средства будут возвращены на ваш банковский счет.',
      '2. Если заказ был оплачен исключительно в бонусном эквиваленте, размер бонуса будет возвращен на ваш бонусный счет.',
      '3. Если заказ был оплачен путем бонусов и денежных средств одновременно, но заказ был отменен, данная сумма средств в бонусном эквиваленте будет возвращена на ваш счет.',
      'В любой момент времени пользователь вправе потребовать возврат суммы заказа (когда услуга не была оказана с нашей стороны, по техническим причинам) путем направления соответствующего требования на почту futopedia@yandex.ru. В данном обращении пользователь должен указать: 1. Способ регистрации на сайте (email / прочий способ регистрации). 2. Реквизиты банковской карты, либо другого платежного инструмента, поддерживаемого нашим платежным агрегатором. Возврат возможен только на ту карту / платежный инструмент, с которой была произведена оплата. 3. Размер суммы заказа, необходимого к возврату. В течение 5 рабочих дней после получения обращения требование о возврате будет удовлетворено.',
      'Услуга по сопровождению сделки в передаче игровой валюты FUT является невозвратной, т.к. цифровой товар, полученный пользователем в ходе сделки имеет индивидуально-определенные свойства и может быть использован исключительно приобретающим его потребителем. В случае запроса возврата средств Вы соглашаетесь возместить нам все издержки, связанные с проведением транзакций по вводу и выводу средств. Вы соглашаетесь не иметь претензий к нам, нашими дочерними компаниями, аффилированным лицам, связанным сторонам, должностным лицам, директорам, сотрудникам, агентам, независимым подрядчикам, рекламодателям, партнерам и соучредителям.',
    ],
  },
]
