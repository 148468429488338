import React from 'react'

import Button, { ButtonSize } from 'shared/components/Button'
import Tooltip from 'shared/components/Tooltip'
import Typography from 'shared/components/Typography'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import useStart from '../../hooks/useStart'

import styles from './BuyForm.module.css'

export const SendButton = () => {
  const { isDisabled, isLoading, tooltipText, startHandler } = useStart()

  const { isMobile } = useWindowSize()

  const buttonSize: ButtonSize = isMobile ? ButtonSize.s : ButtonSize.l

  return (
    <>
      <div className={styles.button}>
        <Tooltip title={tooltipText} disabled={!tooltipText}>
          <Button onClick={startHandler} fullWidth size={buttonSize} disabled={isDisabled} loading={isLoading}>
            Купить
          </Button>
        </Tooltip>
      </div>
      {isLoading && (
        <div className={styles.status}>
          <Typography>Это займет не более двух минут,</Typography>
          <Typography>пожалуйста, подождите</Typography>
        </div>
      )}
    </>
  )
}
