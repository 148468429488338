import React from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from 'shared/components/Spinner'
import Typography from 'shared/components/Typography'
import { getBuyNowPrice, getStartingBid } from 'shared/features/Buy/ducks/selectors'
import { Player, RequiredPlayersCount } from 'shared/features/Buy/types'

import styles from './Step3.module.css'

type Props = {
  player: Player
  requiredPlayersCount: RequiredPlayersCount
  currentPlayersCount: number
}

export const SearchLoading: React.FC<Props> = ({ player, requiredPlayersCount, currentPlayersCount }) => {
  const buyNowPrice = useSelector(getBuyNowPrice)
  const startingBid = useSelector(getStartingBid)
  const {
    display_name: name,
    rating,
    shell: { tag },
  } = player
  return (
    <div className={styles.loading}>
      <Spinner />
      <Typography weight="bold" size="xl" className={styles.loading__title}>
        Ищем вашего игрока
      </Typography>
      <Typography weight="light" spacy className={styles.loading__subTitle}>
        Сейчас мы ищем ваших игроков:
      </Typography>
      <Typography weight="light" spacy className={styles.loading__player}>
        {`${name} (${rating} ${tag})`}
      </Typography>
      <Typography weight="light" spacy>
        {`за ${startingBid} / ${buyNowPrice} монет`}
      </Typography>
      <Typography weight="light" spacy className={styles.loading__description}>
        {`Ваши выбранные игроки ${currentPlayersCount}/${requiredPlayersCount}`}
      </Typography>
    </div>
  )
}
