import React, { useState } from 'react'

import { ReactComponent as ArrowLeft } from 'shared/assets/icons/arrow_left.svg'
import { ReactComponent as ArrowRight } from 'shared/assets/icons/arrow_right.svg'
import Button, { ButtonSize } from 'shared/components/Button'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import { InstructionTab } from '../../typings'

import { TabButtons } from './TabButtons'
import { InstructionTab as Tab } from './InstructionTab'
import styles from './InstructionTabs.module.css'
import { Images } from './Images'

export const tabs: InstructionTab[] = [
  {
    name: 'Шаг 1',
    title: 'Покупка игроков',
    image: '/images/slide_1.png',
    descripiton:
      'Открываете трансферный рынок (на консоли, в веб-приложении или в компаньоне). Система предложит вам найти определённых игроков и купить их за указанную цену.',
  },
  {
    name: 'Шаг 2',
    title: 'Продажа игроков',
    image: '/images/slide_2.png',
    descripiton: 'Вы выставляете купленных игроков на трансферный рынок по цене, которая указана на сайте.',
  },
  {
    name: 'Шаг 3',
    title: 'Подтверждение',
    image: '/images/slide_3.png',
    descripiton:
      'Через поисковую строку найдите игроков, выставленных вами на трансферном рынке и подтвердите покупку данных игроков.',
  },
  {
    name: 'Шаг 4',
    title: 'Повторение пунктов 1-3',
    image: '/images/slide_4.png',
    descripiton:
      'Чаще всего, передача монет происходит не сразу. Сразу после покупки игрока нашей системой, мы предложим купить ещё одного игрока. Для этого вам потребуется перейти на “Шаг 1”.',
    info: 'Этот цикл закончится, когда все ваши монеты окажутся у вас на балансе',
  },
]

export const InstructionTabs = () => {
  const { isMobile } = useWindowSize()
  const [tab, setTab] = useState<InstructionTab>(tabs[0])

  const currentTabIndex = tabs.indexOf(tab)

  const sizeBtn: ButtonSize = isMobile ? ButtonSize.m : ButtonSize.l

  const prevTab = () => {
    setTab(tabs[currentTabIndex - 1])
  }
  const nextTab = () => {
    setTab(tabs[currentTabIndex + 1])
  }

  return (
    <div className={styles.content}>
      <div className={styles.tabs}>
        <TabButtons onClick={setTab} checked={tab.name} />
        <Tab description={tab.descripiton} title={tab.title} info={tab.info} />
        <div className={styles.arrowBtns}>
          <Button
            size={sizeBtn}
            color="lightGray"
            outline
            circle
            icon={ArrowLeft}
            onClick={prevTab}
            disabled={currentTabIndex === 0}
          />
          <Button
            size={sizeBtn}
            color="lightGray"
            outline
            circle
            icon={ArrowRight}
            onClick={nextTab}
            disabled={currentTabIndex === tabs.length - 1}
          />
        </div>
      </div>
      <Images src={tab.image} />
    </div>
  )
}
