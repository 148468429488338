import React, { useEffect } from 'react'

import Typography from 'shared/components/Typography'
import { StepTitle } from 'shared/features/Buy/constants'
import { ReactComponent as FuttyCoinsIcon } from 'shared/assets/icons/futty-coins.svg'
import { closeConnection } from 'shared/features/WebSocket/WebSocketService'

import { Step } from '../Step'

import styles from './Final.module.css'
import { RatingForm } from './RatingForm'

export const Final = () => {
  useEffect(() => {
    closeConnection()
  }, [])

  return (
    <Step title={StepTitle.Final} icon={FuttyCoinsIcon}>
      <Typography weight="light" size="l" className={styles.description}>
        Пожалуйста оставьте отзыв
      </Typography>
      <RatingForm />
    </Step>
  )
}
