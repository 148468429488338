import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'shared/components/Button'
import { Divider } from 'shared/components/Divider'
import { Heading } from 'shared/components/Heading'
import { DEFAULT_GET_PARAMS_REVIEWS } from 'shared/features/Reviews/constants'
import { getReviews, getReviewsFilter } from 'shared/features/Reviews/ducks/selectors'
import { actions as reviewsActions } from 'shared/features/Reviews/ducks'
import { reviews as reviewsRoute } from 'shared/constants/routes'
import { useWindowSize } from 'shared/hooks/useWindowSize'

import { ReviewsSlider } from '../ReviewsSlider'
import { ReviewsMobileSlider } from '../ReviewsMobileSlider/ReviewsMobileSlider'

import styles from './Reviews.module.css'

export const Reviews = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()
  const reviews = useSelector(getReviews)
  const filter = useSelector(getReviewsFilter)

  useEffect(() => {
    dispatch(reviewsActions.fetchReviews({ ...DEFAULT_GET_PARAMS_REVIEWS, size: isMobile ? 5 : 10, ...filter }))
  }, [dispatch])

  const showAll = () => {
    history.push(reviewsRoute.path)
  }

  return (
    <div className={styles.root}>
      <Divider className={styles.divider} />
      <Heading>Отзывы</Heading>
      {isMobile ? <ReviewsMobileSlider reviews={reviews} /> : <ReviewsSlider reviews={reviews} />}
      <Button onClick={showAll} outline className={styles.showAll}>
        Показать все
      </Button>
    </div>
  )
}
